import React from "react";

const BasicSkeleton = ({ count = 6 }) => {
  const widths = ["1/1", "1/2", "1/1", "1/3", "1/4", "1/2"];
  return (
    <div role="status" className="w-full animate-pulse">
      {[...Array(count)].map((e, i) => (
        <div
          key={i}
          className={
            "h-2 bg-gray-250 rounded-full dark:bg-gray-70 w-" +
            widths[i % 6] +
            " mb-2.5"
          }
        />
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default BasicSkeleton;
