import React from "react";

function Modal(props) {
  const {
    icon: IconComponent,
    iconName: name, // No default value assigned
    actionsPannel,
    children,
    color = "primary",
    bg = "white",
    handleCloseModal,
    size = "w-1/3",
    title,
    closeModal = true,
  } = props;

  const CloseIcon = () => (
    <span className="absolute top-0 end-0 p-4">
      <button
        onClick={handleCloseModal}
        className={`focus:outline-none focus:border-none hover:text-${color} 
        p-2 rounded-full inline-flex items-center ${!closeModal && "hidden"}`}
      >
        <svg
          className={`fill-current text-black hover:text-${color}`}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          ></path>
        </svg>
      </button>
    </span>
  );
  const renderIcon = () => {
    switch (name) {
      case "Delete":
        return (
          <span className="bg-error-100 rounded p-2 text-error-500">
            <IconComponent />
          </span>
        );

      default:
        return IconComponent && <IconComponent />;
    }
  };
  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-60 flex 
    transition ease-in-out duration-700"
    >
      <div className="fixed z-10 inset-0 overflow-y-auto rounded">
        <div
          className="flex items-center justify-center min-h-screen pt-4
        px-4 pb-20 
        text-center sm:block sm:p-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 "></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className={`inline-block align-bottom bg-${bg} rounded-lg text-start 
            overflow-hidden shadow-xl transform transition-all sm:my-8 
            sm:align-middle ${size}`}
            role="dialog"
          >
            <div
              className={`relative px-10 pt-5 bg-${bg} w-full flex items-center gap-3
            rounded-lg`}
            >
              {renderIcon()}
              <h5 className="text-center">{title}</h5>
              {CloseIcon()}
            </div>
            <div className={`bg-${bg}`}>
              <div
                className={`bg-${bg} rounded px-8 pt-6 pb-8 flex flex-col 
              overflow-y-auto h-auto`}
              >
                {children}
              </div>
            </div>
            {actionsPannel}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
