import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

export const branchesRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.BRANCHES_REQUEST),
    switchMap((action) =>
      Api.getBranches(action.payload)
        .then((response) => {
          return actions.branchesRequestSuccess(response);
        })
        .catch((error) => actions.branchesRequestFailed(error))
    )
  );

export default [branchesRequest];
