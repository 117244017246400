import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCategories } from "apis/categories";
import { actions } from "redux/remoteConfig/actions";
import updateLocalStorage from "utils/helpers/updateLocalStorage";
import { useHistory } from "react-router-dom";
import IntlMessages from "components/Utils/intlMessages";
const CategoriesSplash = ({ onClose, isLoading, categoriesData }) => {
  const history = useHistory();
  // const [selectedService, setSelectedService] = useState(
  //   localStorage.getItem("selectedService")
  // );

  // useEffect(() => {
  //   const handleStorageUpdate = () => {
  //     const newSelectedService = localStorage.getItem("selectedService");
  //     setSelectedService(newSelectedService);
  //   };

  //   window.addEventListener("localStorageUpdated", handleStorageUpdate);

  //   return () => {
  //     window.removeEventListener("localStorageUpdated", handleStorageUpdate);
  //   };
  // }, []);

  // const { data: categoriesData, isLoading } = useGetCategories({
  //   service_id: selectedService,
  //   is_tree: true,
  //   enabled: true,
  //   limit: 1000,
  // });

  const handleCategoryClick = (categoryId) => {
    history.push(`/categories/${categoryId}`);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">
          {" "}
          <IntlMessages id="global.selectCategory" />
        </h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4 overflow-y-auto">
        {!isLoading &&
          categoriesData?.data?.map((category) => (
            <div
              key={category.id}
              className={`border rounded-lg p-4 cursor-pointer`}
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.icon}
                alt={category.name}
                className="h-12 mx-auto mb-2"
              />
              <div className="text-center font-semibold">{category.name}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoriesSplash;
