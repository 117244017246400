import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor, queryClient } from "./redux";
// import Boot from './redux/boot'
import Routes from "./router";
import AppProvider from "./AppProvider";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // const [faviconUrl, setFaviconUrl] = useState(null);

  // useEffect(() => {
  //   // Make API call to get favicon URL
  //   fetch(`${process.env.REACT_APP_BASE_BE_API}/setting`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setFaviconUrl(
  //         `${data.response.data.general_setting.fav_icon}?t=${Date.now()}`
  //       );
  //     })
  //     .catch((error) => console.error(error));
  // }, []);
  // console.log(faviconUrl);

  return (
    <>
      {/* {faviconUrl && (
        <Helmet>
          <link rel="shortcut icon" href={faviconUrl} />
        </Helmet>
      )} */}
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppProvider>
              <Routes />
            </AppProvider>
          </PersistGate>
        </Provider>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}

export default App;
