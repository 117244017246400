import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    search: {
      id: "global.search",
      defaultMessage: "Search",
    },
    anySearchPlaceholder: {
      id: "global.anySearchPlaceholder",
      defaultMessage: "What Do You Want To Learn!",
    },
    searchQuestions: {
      id: "global.searchQuestions",
      defaultMessage: "Search all product question",
    },
    anySearchPlaceholderfooter: {
      id: "global.anySearchPlaceholderfooter",
      defaultMessage: "Search for anything",
    },
    fieldAdd: {
      id: "global.fieldAdd",
      defaultMessage: "please add search text",
    },
    showMore: {
      id: "global.showMore",
      defaultMessage: "show more",
    },
  })
);
