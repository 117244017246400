import React from "react";

const Divider = ({ className = "", py = 5, borderColor = "gray-200" }) => {
  // Tailwind colors mapping (add more as needed)
  const colors = {
    "gray-200": "#EAECF0",
    "gray-600": "#475467",
  };

  // Get the dynamic border color
  const borderColorValue = colors[borderColor] || colors["gray-200"];

  return (
    <div className={`w-full relative py-${py} ${className}`}>
      <div className="absolute inset-0 flex items-center">
        <div
          className="w-full border-b"
          style={{ borderColor: borderColorValue }}
        ></div>
      </div>
    </div>
  );
};

export default Divider;
