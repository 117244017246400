import React from "react";
import BranchesSearch from "components/Search/BranchesSearchForm";
import ProductsSearch from "components/Search/SearchForm";
import { useSelector } from "react-redux";
import LangDropdown from "components/Dropdowns/LangDropdown.js";
import IntlMessages from "components/Utils/intlMessages";
const OtherSplash = ({ onClose, locale }) => {
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">
          {" "}
          <IntlMessages id="global.other" />
        </h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-col gap-2 items-center">
        {location.pathname !== "/" ? (
          !isServiceDetailsLoading &&
          serviceDetails?.filter_type === "products_focused" ? (
            <ProductsSearch />
          ) : (
            <BranchesSearch />
          )
        ) : null}{" "}
        <LangDropdown locale={locale} />
      </div>
    </div>
  );
};

export default OtherSplash;
