import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ErrorMessage } from "formik";
import { useSelector } from "react-redux";

const styles = {
  label: "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-50 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50",
};
function PhoneNumber(props) {
  const {
    setFieldValue,
    value,
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    disabled = false,
    ...rest
  } = props;
  // console.log("setFieldValue type:", typeof setFieldValue);
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <PhoneInput
        international
        // countryCallingCodeEditable={false}
        value={value}
        onChange={(e) => setFieldValue(name, e)}
        className={touched && errors ? styles.fieldError : styles.field}
        name={name}
        disabled={disabled}
        {...rest}
      />
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
  );
}
export default PhoneNumber;
