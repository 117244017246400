import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    locationsModalTitle: {
      id: "maps.locationsModalTitle",
      defaultMessage: " What's your exact location?",
    },
    locationsModalDescription: {
      id: "maps.locationsModalDescription",
      defaultMessage:
        "Providing your location enables more accurate search and delivery ETA, seamless order tracking and personalised recommendations.",
    },
    noResultsFound: {
      id: "maps.noResultsFound",
      defaultMessage: "No Results Found",
    },
    startTyping: {
      id: "maps.startTyping",
      defaultMessage: "Start Typing",
    },
    findChooseLocation: {
      id: "maps.findChooseLocation",
      defaultMessage: "Find and choose a location*",
    },
    locationNotSupported: {
      id: "maps.locationNotSupported",
      defaultMessage: "Location not supported",
    },
    chooseLocation: {
      id: "maps.chooseLocation",
      defaultMessage: "Choose a location",
    },
    searching: {
      id: "maps.searching",
      defaultMessage: "Searching",
    },
    pickThisAddress: {
      id: "maps.pickThisAddress",
      defaultMessage: "Pick this address",
    },
    pleaseChooseLocation: {
      id: "maps.pleaseChooseLocation",
      defaultMessage: "Please Choose Location",
    },
  })
);
