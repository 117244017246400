import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import IntlMessages from "components/Utils/intlMessages";
import { LangIcon } from "assets/icons";
import actions from "redux/languageSwitcher/actions";
import { actions as userprofileActions } from "redux/userprofile/actions";
import { useDispatch, useSelector } from "react-redux";
import listenForOutsideClick from "utils/helpers/listen-for-outside-clicks";
import Button from "components/Buttons/Button";
import client from "utils/plugins/ApiClient";
import { useUpdateSetting } from "apis/profile";
import arFlag from "assets/images/uaeFlag.png";
import enFlag from "assets/images/usaFlag.png";
const LangDropdown = ({ locale, isLoggedIn }) => {
  const dispatch = useDispatch();

  const { mutate: updateSetting } = useUpdateSetting();
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const changeLanguageHandler = () => {
    if (isLoggedIn) {
      let settingData;
      locale === "en"
        ? (settingData = { default_locale: "ar" })
        : (settingData = { default_locale: "en" });

      updateSetting(settingData, {
        onSuccess: () => {
          dispatch(
            userprofileActions.updateProfileReducerData({
              default_locale: settingData.default_locale,
            })
          );
        },
      });
    } else {
      locale === "en"
        ? dispatch(actions.changeLanguage("arabic"))
        : dispatch(actions.changeLanguage("english"));
    }
  };

  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );
  // useEffect(() => {
  //   if (locale === "ar") {
  //     client.defaults.headers.Language = "ar";
  //   } else {
  //     client.defaults.headers.Language = "en";
  //   }
  //   // console.log(client.defaults.headers.Language);
  // }, [locale]);
  return (
    <>
      <Button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        variant="outlined"
        size="large"
        className="flex items-center gap-2"
      >
        {/* <LangIcon size={20} className="text-gray-30" /> */}
        {/* <span className="hidden lg:block text-xxs text-secondary-950 font-inter-regular">
          <IntlMessages id="global.lang" />
        </span> */}
        {locale === "en" && <img src={enFlag} className="w-6" />}
        {locale === "ar" ? (
          <span className="hidden md:block">Arabic</span>
        ) : (
          <span className="hidden md:block">English</span>
        )}
        {locale === "ar" && <img src={arFlag} className="w-6" />}
      </Button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-start py-2 list-none text-start rounded shadow-lg min-w-[10rem]"
        }
      >
        <span
          className={
            "text-xs pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
          }
        ></span>
        <button
          disabled={locale === "ar"}
          onClick={changeLanguageHandler}
          className={`text-xs text-start hover:text-primary py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-bluegray-70 ${
            locale === "ar" && "text-primary"
          }`}
        >
          <IntlMessages id="global.arabic" />
        </button>
        <button
          disabled={locale === "en"}
          onClick={changeLanguageHandler}
          className={`text-xs text-start hover:text-primary py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-bluegray-70 ${
            locale === "en" && "text-primary"
          }`}
        >
          <IntlMessages id="global.english" />
        </button>
      </div>
    </>
  );
};

export default LangDropdown;
