/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "components/Buttons/Button";
import IntlMessages from "components/Utils/intlMessages";
import LoginDropdown from "components/Dropdowns/LoginDropdown.js";
import LangDropdown from "components/Dropdowns/LangDropdown.js";
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown.js";
import CurrencyDropdown from "components/Dropdowns/CurrencyDropdown";
import actions from "redux/languageSwitcher/actions";
import { useDispatch, useSelector } from "react-redux";
import BranchesSearch from "components/Search/BranchesSearchForm";
import ProductsSearch from "components/Search/SearchForm";
import { FavIcon, CartIcon, NotiIcon, UserIcon } from "assets/icons";
import AuthContainer from "components/Authentication/AuthContainer";
import { get as _get } from "lodash";
import { actions as orderActions } from "redux/order/actions";
import MobileNavbar from "./MobileNavbar";
import { SearchIcon } from "assets/icons";
export default function Navbar({ settings, isLoading, locale }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const User = useSelector((state) => state.User);

  const loggedUser = User.user;
  const isLoggedIn = _get(User, "token", false);
  const { cart, isLoading: isFetching } = useSelector(
    (state) => state.Order.orders
  );
  const [isShowModalSignIn, setIsShowModalSignIn] = useState(false);
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const handleTriggerModalSignIn = () =>
    setIsShowModalSignIn(!isShowModalSignIn);

  return (
    <>
      <AuthContainer
        isShowModalSignIn={isShowModalSignIn}
        handleTriggerModalSignIn={handleTriggerModalSignIn}
      />
      <nav className="sticky top-0  w-full flex-col flex flex-wrap items-center justify-between py-3 navbar-expand-lg bg-white border-b border-gray-80 z-50">
        <div className="container flex flex-wrap items-center justify-between">
          <div className="w-full lg:w-auto relative flex justify-between lg:static lg:block lg:justify-start">
            <Link
              to={isLoggedIn ? "/home" : "/"}
              className="inline-block my-2 lg:my-0 me-4"
            >
              {/* {locale === "ar" ? (
                <img
                  alt={settings?.general_setting.store_name_ar}
                  src={settings?.general_setting.store_logo_ar}
                  className="w-28 md:w-32 max-h-10"
                />
              ) : (
                <img
                  alt={settings?.general_setting.store_name_en}
                  src={settings?.general_setting.store_logo_en}
                  className="w-28 md:w-32 max-h-10"
                />
              )} */}
              <img
                alt={settings?.app_name}
                src={settings?.logo}
                className="w-28 md:w-32 max-h-10"
              />
            </Link>
            <div className="flex md:hidden items-center gap-2">
              <Button
                onClick={() => setSearchOpen(!searchOpen)}
                variant="outlined"
                size="large"
              >
                <SearchIcon size={24} />
              </Button>

              <LangDropdown locale={locale} />
            </div>
            <button
              className="hidden md:block lg:hidden cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={`w-full flex md:hidden items-center gap-2 bg-white lg:shadow-none ${
              searchOpen ? "block" : "hidden"
            }`}
          >
            {!isServiceDetailsLoading &&
            serviceDetails?.filter_type === "products_focused" ? (
              <ProductsSearch />
            ) : (
              <BranchesSearch />
            )}
          </div>
          <div
            className={
              "lg:ps-52 lg:flex flex-grow items-center justify-between gap-3 bg-white lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="navbar"
          >
            {!isServiceDetailsLoading &&
            serviceDetails?.filter_type === "products_focused" ? (
              <ProductsSearch />
            ) : (
              <BranchesSearch />
            )}

            {/* <AutocompleteSearchInput options={options} /> */}

            <ul className="flex my-2 md:my-0 gap-3 justify-center lg:justify-start list-none">
              <li className="flex items-center">
                <Link to="/cart" className="inline-flex items-center relative">
                  <div className="relative">
                    <CartIcon size={24} className="text-gray-30" />

                    {!isFetching && cart?.items_quantity > 0 ? (
                      <div className="flex absolute -top-2 -right-1 justify-center items-center w-4 h-4 text-xxs font-bold text-white bg-primary-500 rounded-full">
                        {cart?.items_quantity}
                      </div>
                    ) : null}
                  </div>
                  {/* <span className="hidden lg:block text-base text-secondary-950 font-inter-regular">
                    <IntlMessages id="global.cart" />
                  </span> */}
                  <span className="sr-only">Cart</span>
                </Link>
              </li>
              {isLoggedIn ? (
                <>
                  <li className="flex items-center">
                    <UserOptionsDropdown />
                  </li>
                  <li className="flex items-center">
                    <Button
                      variant="text"
                      size="auto"
                      onClick={() => history.push("/account/my-favourites")}
                    >
                      <FavIcon size={20} className="text-gray-30" />
                      <span className="hidden lg:block text-base text-secondary-950 font-inter-regular">
                        <IntlMessages id="global.favourite" />
                      </span>
                    </Button>
                  </li>
                </>
              ) : (
                <>
                  <li className="flex items-center">
                    <Button
                      // onClick={() => history.push("/signin")}
                      onClick={handleTriggerModalSignIn}
                      variant="solid"
                      className="flex items-center gap-1"
                      size="large"
                    >
                      <UserIcon size={20} />

                      <IntlMessages id="global.loginOrRegister" />
                    </Button>
                  </li>
                </>
              )}

              {/* <li className="flex items-center">
                <CurrencyDropdown />
              </li> */}
              <li className="flex items-center">
                <LangDropdown locale={locale} isLoggedIn={isLoggedIn} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <MobileNavbar
        locale={locale}
        isLoggedIn={isLoggedIn}
        handleTriggerModalSignIn={handleTriggerModalSignIn}
      />
    </>
  );
}
