import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";
export const getSettings = () => {
  return ApiClient.get("/remote-config")
    .then((response) => {
      return response.data.response.data;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};
