import axios from "utils/plugins/ApiClient";
import { useInfiniteQuery, useQuery } from "react-query";
// Get Categories  ##################################
const fetchCategories = async ({ queryKey }) => {
  const { service_id, enabled, limit, is_tree } = queryKey[1];

  const { data } = await axios.get(
    `/categories?service_id=${service_id}&is_tree=${is_tree}&limit=${limit}`
  );
  return data.response;
};

export const useGetCategories = (props) => {
  const queries = {
    service_id: props?.service_id || "",
    is_tree: props?.is_tree || false,
    enabled: props?.enabled || false,
    limit: props?.limit || "",
  };
  return useQuery(["categories", queries], fetchCategories, {
    enabled: queries.enabled,
  });
};

// Get Categories  ##################################
const fetchFiltrationCategories = async ({ pageParam = 1, queryKey }) => {
  const { parent_id, limit } = queryKey[1];

  // Build the URL conditionally
  let url = `/categories?page=${pageParam}`;
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (parent_id) {
    url += `&parent_id=${parent_id}`;
  }
  const { data } = await axios.get(url);
  return data.response;
};

export const useGetFiltrationCategories = (props) => {
  const queries = {
    limit: props?.limit || "", // Use undefined instead of empty string
    parent_id: props?.parent_id || "",
    enabled: props?.enabled || false,
  };

  return useInfiniteQuery(
    ["filtration-categories", queries],
    fetchFiltrationCategories,
    {
      enabled: queries.enabled,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.meta.current_page < lastPage.meta.last_page) {
          return lastPage.meta.current_page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

// Get Branch Categories  ##################################
const fetchFiltrationBranchCategories = async ({ pageParam = 1, queryKey }) => {
  const { service_id, limit } = queryKey[1];

  const { data } = await axios.get(
    `/branch-categories?service_id=${service_id}&page=${pageParam}&limit=${limit}`
  );
  return data.response;
};

export const useGetPaginatedBranchCategories = (props) => {
  const queries = {
    limit: props?.limit || "",
    enabled: props?.enabled || false,
    service_id: props?.service_id || "",
  };
  return useInfiniteQuery(
    ["filtration-branch-categories", queries],
    fetchFiltrationBranchCategories,
    {
      enabled: queries.enabled,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.meta.current_page < lastPage.meta.last_page) {
          return lastPage.meta.current_page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};
// Get category details
const fetchCategory = async (id) => {
  const { data } = await axios.get(`/categories/${id}`);
  return data.response.data;
};
export const useGetCategory = (id) => {
  return useQuery(["category-details", id], () => fetchCategory(id));
};
