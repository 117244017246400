import { actionTypes } from "./actions";

const initState = {
  serviceProducts: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    products: [],
  },
};

const productsRequest = (state, payload) => ({
  ...state,
  serviceProducts: { isLoading: true },
});
const productsRequestSuccess = (state, payload) => ({
  ...state,
  serviceProducts: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    total_pages: payload?.meta?.last_page,
    products: payload?.data,
  },
});
const productsRequestFailed = (state, payload) => ({
  ...state,
  serviceProducts: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

const toggleItemFav = (state, payload) => {
  // console.log("Payload:", payload);
  const updatedProducts = state.serviceProducts.products.map((product) => {
    if (product.id === payload.id) {
      const updatedProduct = {
        ...product,
        favorite: product.favorite ? 0 : 1,
      };
      // console.log("Updated Product:", updatedProduct);
      return updatedProduct;
    }
    // console.log("Unchanged Product:", product);
    return product;
  });

  // console.log("Updated Products Array:", updatedProducts);

  return {
    ...state,
    serviceProducts: {
      ...state.serviceProducts,
      products: updatedProducts,
    },
  };
};

const resetProducts = (state) => ({
  ...state,
  serviceProducts: {
    ...state.serviceProducts,
    products: [],
  },
});
export default function productsReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.PRODUCTS_REQUEST:
      return productsRequest(state, payload);
    case actionTypes.PRODUCTS_REQUEST_SUCCESS:
      return productsRequestSuccess(state, payload);
    case actionTypes.PRODUCTS_REQUEST_FAILED:
      return productsRequestFailed(state, payload);
    case actionTypes.TOGGLE_ITEM_FAV:
      return toggleItemFav(state, payload);
    case actionTypes.RESET_PRODUCTS:
      return resetProducts(state);
    default:
      return state;
  }
}
