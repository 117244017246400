import renameFunc from "utils/helpers/renameFunc";
import { camelCase as _camelCase, get as _get } from "lodash";

export const actionTypes = {
  SERVICES_REQUEST: "SERVICES_REQUEST 🚀 💬",
  SERVICES_REQUEST_SUCCESS: "SERVICES_REQUEST_SUCCESS 🚀 ✅",
  SERVICES_REQUEST_FAILED: "SERVICES_REQUEST_FAILED 🚀 ❌",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
