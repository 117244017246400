import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

export const remoteConfigRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.REMOTE_CONFIG_REQUEST),
    switchMap((action) =>
      Api.getSettings(action.payload)
        .then((response) => {
          return actions.remoteConfigRequestSuccess(response);
        })
        .catch((error) => actions.remoteConfigRequestFailed(error))
    )
  );

export default [remoteConfigRequest];
