import axios from "utils/plugins/ApiClient";
import Messages from "views/Account/i18nStrings";
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import HandleAxiosError from "utils/helpers/HandleAxiosError";


// Update profile
const updateProfile = async (userInfo) => {
  const { data } = await axios.patch('/user/update-profile', userInfo)
  return data
}

export const useUpdateProfile = () => {
  return useMutation(updateProfile, {
    onSuccess: (data) => {
      toast.success(data.message)
    },
    onError: (err) => {
      toast.error(HandleAxiosError(err))
    },
  })
}


// change password
const changePassword = async (userData) => {
  const { data } = await axios.patch('/user/update-password', userData)
  return data
}

export function useChangePassword() {
  return useMutation(changePassword, {
    onSuccess: (data) => {
      toast.success(data.message)
    },
    onError: (error) => {
      toast.error(HandleAxiosError(error))
    },
  })
}


// Update setting
const updateSetting = async (userInfo) => {
  const { data } = await axios.put('/user/update-setting', userInfo)
  return {
    data,
    hideMsg: Object.keys(userInfo).length === 1 && Object.keys(userInfo)[0] === 'default_locale'
  }
}

export const useUpdateSetting = () => {
  return useMutation(updateSetting, {
    onSuccess: ({data,hideMsg}) => {
      !hideMsg && toast.success(data.message)
    },
    onError: (err) => {
      toast.error(HandleAxiosError(err))
    },
  })
}
