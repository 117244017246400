import { actionTypes } from "./actions";

const initState = {
  districtServices: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    services: [],
  },
};

const servicesRequest = (state, payload) => ({
  ...state,
  districtServices: { isLoading: true },
});
const servicesRequestSuccess = (state, payload) => ({
  ...state,
  districtServices: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    services: payload?.data,
  },
});
const servicesRequestFailed = (state, payload) => ({
  ...state,
  districtServices: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

export default function servicesReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.SERVICES_REQUEST:
      return servicesRequest(state, payload);
    case actionTypes.SERVICES_REQUEST_SUCCESS:
      return servicesRequestSuccess(state, payload);
    case actionTypes.SERVICES_REQUEST_FAILED:
      return servicesRequestFailed(state, payload);
    default:
      return state;
  }
}
