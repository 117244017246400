import { lazy } from "react";

export const PUBLIC_ROUTE = {
  HOME: "/home",
  LANDING: "/",
  RESULTS: "/search-results",
  BRANCHESRESULTS: "/branches-results",
  BRANCH: "/branches/:id",
  CATEGORY: "/categories/:id",
  PRODUCT: "/products/:id",
};

export const PRIVATE_ROUTE = {
  CART: "/cart",
  CHECKOUT: "/checkout",
  ACCOUNT: "/account",
};

export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.HOME,
    exact: true,
    component: lazy(() => import("views/Home")),
    locationRequired: true,
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("views/Landing")),
    locationRequired: false,
  },
  {
    path: PUBLIC_ROUTE.RESULTS,
    exact: true,
    component: lazy(() => import("views/SearchResults")),
  },
  {
    path: PUBLIC_ROUTE.BRANCHESRESULTS,
    exact: true,
    component: lazy(() => import("views/BranchesResults")),
  },
  {
    path: PUBLIC_ROUTE.BRANCH,
    exact: true,
    component: lazy(() => import("views/Branch")),
    locationRequired: true,
  },
  {
    path: PUBLIC_ROUTE.CATEGORY,
    exact: true,
    component: lazy(() => import("views/Category")),
    locationRequired: true,
  },
  {
    path: PUBLIC_ROUTE.PRODUCT,
    exact: true,
    component: lazy(() => import("views/Product")),
    locationRequired: true,
  },
];

export const privateRoutes = [
  {
    path: PRIVATE_ROUTE.CART,
    exact: true,
    component: lazy(() => import("views/Cart")),
    locationRequired: true,
  },
  {
    path: PRIVATE_ROUTE.CHECKOUT,
    exact: true,
    component: lazy(() => import("views/Checkout")),
    locationRequired: true,
  },
  {
    path: PRIVATE_ROUTE.ACCOUNT,
    component: lazy(() => import("views/Account")),
    exact: false,
    locationRequired: false,
  },
];
export const NotFound = lazy(() => import("views/404"));
