import React from "react";
import { NoDataIcon } from "assets/icons";
const NoData = ({ title, subtitle }) => {
  return (
    <section className="w-full items-center justify-center text-center md:text-start py-5 flex flex-col gap-3">
      <NoDataIcon size={100} className=" text-primary-400" />
      <h4 className="text-secondary-950 text-lg">{title}</h4>
      <p>{subtitle}</p>
    </section>
  );
};

export default NoData;
