import React, { useEffect } from "react";
import AuthenticationNavbar from "components/Navbars/AuthenticationNavbar";
import { actions as settingsActions } from "redux/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { actions as remoteConfigActions } from "redux/remoteConfig/actions";
const AuthLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { config: settings, isLoading } = useSelector(
    (state) => state.Settings.settings
  );
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  // console.log(locale);
  useEffect(() => {
    if (!isLoading && !settings) {
      dispatch(settingsActions.settingsRequest());
    }
    // console.log(settings);
  }, [isLoading, settings]);
  useEffect(() => {
    if (!isRemoteConfigLoading && !remoteConfig) {
      dispatch(remoteConfigActions.remoteConfigRequest());
    }
  }, [isRemoteConfigLoading, remoteConfig]);
  return (
    <>
      <AuthenticationNavbar
        settings={settings}
        isLoading={isLoading}
        locale={locale}
      />
      <div className="container flex flex-wrap justify-center min-h-[calc(100vh_-_4rem)] items-center py-10">
        <div className="w-full lg:w-5/12 md:w-8/12 px-4">{children}</div>
      </div>
    </>
  );
};

export default AuthLayout;
