import { isArray as _isArray } from "lodash";

export default function HandleAxiosError(error) {
  try {
    if (error.response) {
      const { status, data } = error.response;

      // Handle status code 422 (Unprocessable Entity) specifically
      if (status === 422) {
        // 422 errors often contain validation errors
        if (data.errors) {
          const errorsArr = Object.values(data.errors);
          if (_isArray(errorsArr[0])) {
            // Return the first error message from the array
            return errorsArr[0][0] || "Validation error occurred.";
          } else {
            // Return the first error message if not an array
            return errorsArr[0] || "Validation error occurred.";
          }
        }
        return data.message || "Validation error occurred.";
      }

      // Handle status code 400 (Bad Request)
      if (status === 400) {
        if (data.errors) {
          const errorContent = data.errors.error;
          if (_isArray(errorContent)) {
            return errorContent[0]; // Return the first element of the array
          } else {
            return errorContent;
          }
        }
        return data.message || "Bad request error occurred.";
      }

      // Handle other status codes
      return data.message || "An error occurred.";
    } else if (error.request) {
      return "No response received from server.";
    } else {
      return `Error: ${error.message}`;
    }
  } catch (err) {
    console.error("Error handling failed:", err);
  }
}
