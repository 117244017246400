import React from "react";
import { Link } from "react-router-dom";
const Subcategory = ({ category }) => {
  return (
    <h2 className="text-sm font-inter-medium whitespace-nowrap h-12">
      <Link to={`/categories/${category.id}`}>{category.name}</Link>
    </h2>
  );
};

export default Subcategory;
