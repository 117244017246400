import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import expireReducer from "redux-persist-expire";
import { createEpicMiddleware } from "redux-observable";
import rootReducer from "./root-reducer";
import rootEpics from "./root-epics";

export { default as queryClient } from "./queryClient";
const epicMiddleware = createEpicMiddleware();
const middlewares = [thunk, epicMiddleware];

const persistConfig = {
  key: "@matjr-presist",
  storage,
  whitelist: ["User"],
  transforms: [
    expireReducer("User", {
      expireSeconds: 10,
      expiredState: {},
      // autoExpire: true,
    }),
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const bindMiddleware = (middleware) => {
  if (process?.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(persistedReducer, bindMiddleware(middlewares));
const persistor = persistStore(store);
epicMiddleware.run(rootEpics);
const { getState, dispatch } = store;
export { store, persistor, getState, dispatch };
