import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    exploreServices: {
      id: "navbars.exploreServices",
      defaultMessage: "Explore Services",
    },
    switchedTo: {
      id: "navbars.switchedTo",
      defaultMessage: "Switched To",
    },
    cart: {
      id: "navbars.cart",
      defaultMessage: "Cart",
    },
  })
);
