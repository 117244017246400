import { actionTypes } from "./actions";

const initState = {
  createOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    oldCartMsg: null,
  },
  updateOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  deleteOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  orders: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    cart: null,
  },
  addCoupon: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
};

const ordersRequest = (state, payload) => ({
  ...state,
  orders: { isLoading: true },
});
const ordersRequestSuccess = (state, payload) => {
  // console.log(payload);
  return {
    ...state,
    orders: {
      isLoading: false,
      isSuccess: true,
      // successMessage: payload?.message,
      successMessage: null,
      isFailed: false,
      error: null,
      cart: payload,
    },
  };
};
const ordersRequestFailed = (state, payload) => ({
  ...state,
  orders: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// order
const createOrderRequest = (state, payload) => ({
  ...state,
  createOrder: { isLoading: true },
});

const createOrderSuccess = (state, payload) => {
  // Only process if orders is not loading
  if (state.orders.isLoading) {
    return state;
  }

  if (state.orders.cart.items.length === 0) {
    return {
      ...state,
      orders: {
        ...state.orders,
        cart: {
          ...state.orders.cart,
          items: [payload],
          items_quantity: parseInt(payload.qty),
          price: payload?.order.price,
          total: payload?.order.total,
          cart_total: payload?.order.cart_total,
          tax: payload?.order.tax,
        },
      },
    };
  }

  const existingItemIndex = state.orders.cart.items.findIndex(
    (item) => item.product_id === payload.product_id
  );

  const updatedItems = [...state.orders.cart.items];
  let totalItems = parseInt(state.orders.cart.items_quantity);

  if (existingItemIndex !== -1) {
    const existingItem = updatedItems[existingItemIndex];
    updatedItems[existingItemIndex] = {
      ...existingItem,
      qty: parseInt(payload.qty), // Set to payload.qty
    };
    totalItems = updatedItems.reduce(
      (sum, item) => sum + parseInt(item.qty),
      0
    );
  } else {
    updatedItems.push(payload);
    totalItems += parseInt(payload.qty); // Add payload.qty to total items
  }

  return {
    ...state,
    createOrder: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state.orders,
      cart: {
        ...state.orders.cart,
        items: updatedItems,
        items_quantity: totalItems,
        price: payload?.order.price,
        total: payload?.order.total,
        cart_total: payload?.order.cart_total,
        tax: payload?.order.tax,
      },
    },
  };
};

const createOrderFailed = (state, payload) => {
  // console.log(payload);
  return {
    ...state,
    createOrder: {
      isLoading: false,
      isSuccess: false,
      successMessage: null,
      isFailed: true,
      error: payload,
      oldCartMsg: payload?.response?.status === 400 ? payload?.message : null,
    },
  };
};

const updateOrderRequest = (state, payload) => ({
  ...state,
  updateOrder: { isLoading: true },
});

const updateOrderSuccess = (state, payload) => {
  // console.log(payload);
  if (!state.orders.cart) {
    return {
      ...state,
      orders: {
        ...state.orders,
        cart: {
          items: [payload],
          items_quantity: parseInt(payload.qty),
          price: payload?.order.price,
          total: payload?.order.total,
          cart_total: payload?.order.cart_total,
          tax: payload?.order.tax,
        },
      },
    };
  }

  const existingItemIndex = state.orders.cart.items.findIndex((item) => {
    if (payload.key !== null) {
      return item.key === payload.key;
    } else {
      return item.product_id === payload.product_id;
    }
  });

  const updatedItems = [...state.orders.cart.items];
  let totalItems = parseInt(state.orders.cart.items_quantity);

  if (existingItemIndex !== -1) {
    const existingItem = updatedItems[existingItemIndex];
    totalItems -= parseInt(existingItem.qty);
    updatedItems[existingItemIndex] = {
      ...existingItem,
      price: payload.price,
      qty: parseInt(payload.qty),
    };
  } else {
    updatedItems.push(payload);
  }

  totalItems += parseInt(payload.qty);

  // const existingItemIndex = state.orders.cart.items.findIndex((item) => {
  //   if (payload.key !== null) {
  //     return item.key === payload.key;
  //   } else {
  //     return item.product_id === payload.product_id;
  //   }
  // });

  // const updatedItems = [...state.orders.cart.items];
  // let totalItems = parseInt(state.orders.cart.items_quantity);

  // if (existingItemIndex !== -1) {
  //   const existingItem = updatedItems[existingItemIndex];
  //   totalItems -= parseInt(existingItem.qty);

  //   updatedItems[existingItemIndex] = {
  //     ...existingItem,
  //     price: payload.price,
  //     qty: parseInt(existingItem.qty) + parseInt(payload.qty),
  //   };
  // } else {
  //   updatedItems.push(payload);
  //   totalItems += parseInt(payload.qty);
  // }

  // totalItems = Math.max(totalItems, 0);

  return {
    ...state,
    updateOrder: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state.orders,
      cart: {
        ...state.orders.cart,
        items_quantity: totalItems,
        price: payload?.order.price,
        total: payload?.order.total,
        cart_total: payload?.order.cart_total,
        tax: payload?.order.tax,
        items: updatedItems,
      },
    },
  };
};

const updateOrderFailed = (state, payload) => ({
  ...state,
  updateOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

// delete order
const deleteOrderRequest = (state, payload) => ({
  ...state,
  deleteOrder: { isLoading: true },
});
// const deleteOrderSuccess = (state, payload) => {
//   // const deletedIndex = state.orders.cart.items.findIndex((item) => {
//   //   return item.id === payload.id;
//   // });
//   // console.log(deletedIndex);
//   const filteredItems = state.orders.cart.items.filter(
//     (item) => item.id != payload.id
//   );
//   // console.log(filteredItems);
//   // console.log(
//   //   state.orders.cart.items.filter((item) => item.id !== payload.id)
//   // );
//   // console.log(payload.id);
//   return {
//     ...state,
//     deleteOrder: {
//       isLoading: false,
//       isSuccess: true,
//       successMessage: payload?.message,
//       isFailed: false,
//       error: null,
//     },
//     orders: {
//       ...state,
//       cart: {
//         ...state.orders.cart,
//         items_quantity:
//           parseInt(state.orders.cart.items_quantity) -
//           parseInt(payload.parent.qty),
//         price: payload.price,
//         total: payload.total,
//         tax: payload.tax,
//         items: filteredItems,

//         // items: state.orders.cart.items.splice(deletedIndex, 1),
//       },
//     },
//   };
// };

const deleteOrderSuccess = (state, payload) => {
  const deletedItemQty = payload.qty; // get the quantity of the deleted item
  const filteredItems = state.orders.cart.items.filter(
    (item) => item.id != payload.id
  );
  return {
    ...state,
    deleteOrder: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state.orders,
      cart: {
        ...state.orders.cart,
        items_quantity:
          parseInt(state.orders.cart.items_quantity) - parseInt(deletedItemQty), // subtract the quantity of the deleted item from the items_quantity count
        price: payload.order.price,
        total: payload.order.total,
        cart_total: payload?.order.cart_total,
        tax: payload.order.tax,
        items: filteredItems,
      },
    },
  };
};

const deleteOrderFailed = (state, payload) => ({
  ...state,
  deleteOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
const addCouponRequest = (state, payload) => ({
  ...state,
  addCoupon: { isLoading: true },
});
const addCouponSuccess = (state, payload) => {
  // console.log(payload);

  return {
    ...state,

    addCoupon: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state,
      cart: {
        ...state.orders.cart,
        items_quantity:
          state.orders.cart?.items_quantity > 0
            ? state.orders.cart?.items_quantity + payload?.qty
            : payload?.qty,
        price: payload?.order.price,
        total: payload?.order.total,
        cart_total: payload?.order.cart_total,
        tax: payload?.order.tax,
        items: state.orders.cart?.items?.concat(payload),
      },
    },
  };
};
const addCouponFailed = (state, payload) => ({
  ...state,
  addCoupon: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

const toggleItemFav = (state, payload) => {
  const existingItemIndex = state.orders.cart.items.findIndex(
    (item) => item.id === payload.id
  );

  if (existingItemIndex === -1) {
    return state; // Return the original state if the item doesn't exist
  }

  const updatedItems = [...state.orders.cart.items];
  const existingItem = updatedItems[existingItemIndex];

  updatedItems[existingItemIndex] = {
    ...existingItem,

    favorite: !existingItem.favorite, // Toggle the favorite key
  };

  return {
    ...state,
    orders: {
      ...state.orders,
      cart: {
        ...state.orders.cart,
        items: updatedItems,
      },
    },
  };
};

const handleCouponRequest = (state, payload) => {
  return {
    ...state,
    orders: {
      ...state,
      cart: {
        ...state.orders.cart,
        coupon_id: payload?.coupon_id,
        coupon_code: payload?.coupon_code,
        coupon_discount: payload?.coupon_discount,
        price: payload?.price,
        total: payload?.total,
        tax: payload?.tax,
        delivery_price: payload?.delivery_price,
      },
    },
  };
};

const clearError = (state, payload) => {
  return {
    ...state,
    ...(payload === "createOrder" || !payload
      ? { createOrder: { ...state.createOrder, error: null } }
      : {}),
    ...(payload === "updateOrder" || !payload
      ? { updateOrder: { ...state.updateOrder, error: null } }
      : {}),
    ...(payload === "deleteOrder" || !payload
      ? { deleteOrder: { ...state.deleteOrder, error: null } }
      : {}),
    ...(payload === "orders" || !payload
      ? { orders: { ...state.orders, error: null } }
      : {}),
    ...(payload === "addCoupon" || !payload
      ? { addCoupon: { ...state.addCoupon, error: null } }
      : {}),
  };
};

export default function orderReducer(state = initState, { type, payload }) {
  switch (type) {
    // create order
    case actionTypes.CREATE_ORDER_REQUEST:
      return createOrderRequest(state, payload);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, payload);
    case actionTypes.CREATE_ORDER_FAILED:
      return createOrderFailed(state, payload);
    // update order
    case actionTypes.UPDATE_ORDER_REQUEST:
      return updateOrderRequest(state, payload);
    case actionTypes.UPDATE_ORDER_SUCCESS:
      return updateOrderSuccess(state, payload);
    case actionTypes.UPDATE_ORDER_FAILED:
      return updateOrderFailed(state, payload);
    //delete order
    case actionTypes.DELETE_ORDER_REQUEST:
      return deleteOrderRequest(state, payload);
    case actionTypes.DELETE_ORDER_SUCCESS:
      return deleteOrderSuccess(state, payload);
    case actionTypes.DELETE_ORDER_FAILED:
      return deleteOrderFailed(state, payload);
    case actionTypes.ORDERS_REQUEST:
      return ordersRequest(state, payload);
    case actionTypes.ORDERS_REQUEST_SUCCESS:
      return ordersRequestSuccess(state, payload);
    case actionTypes.ORDERS_REQUEST_FAILED:
      return ordersRequestFailed(state, payload);
    // add coupon to order
    case actionTypes.ADD_COUPON_REQUEST:
      return addCouponRequest(state, payload);
    case actionTypes.ADD_COUPON_SUCCESS:
      return addCouponSuccess(state, payload);
    case actionTypes.ADD_COUPON_FAILED:
      return addCouponFailed(state, payload);
    case actionTypes.TOGGLE_ITEM_FAV:
      return toggleItemFav(state, payload);
    case actionTypes.HANDLE_COUPON_REQUEST:
      return handleCouponRequest(state, payload);
    case actionTypes.CLEAR_ERROR:
      return clearError(state, payload);
    default:
      return state;
  }
}
