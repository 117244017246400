import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";
import { toast } from "react-toastify";

export const getProducts = (payload) => {
  // Check if page and service_id are provided in the payload
  if (!payload.page || !payload.service_id) {
    console.warn("Missing page or service_id in payload");
    return Promise.resolve([]); // Return an empty array or handle it as needed
  }

  return ApiClient.get(
    `/products?page=${payload.page}&service_id=${payload.service_id}`
  )
    .then((response) => {
      // console.log(response.data.response.data)
      return response.data.response;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};
