
import React from "react";
import Select from "react-select";
import IntlMessages from "components/Utils/intlMessages";


export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  secLabel='',
  value,
  onChange=()=>{},
}) => {
  const onChangeSelect = (option) => {
    onChange(option)
    form.setFieldValue(
      field.name,
      isMulti
        ? (option).map((item) => item.id)
        : (option).id
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.id) >= 0)
        : options.find(option => option.id === field.value);
    } else {
      return isMulti ? [] : ("");
    }
  };

  return (
    <Select
      className={className}
      name={field.name}
      value={value ? getValue() : null}
      onChange={onChangeSelect}
      placeholder={placeholder}
      options={options}
      getOptionLabel ={(option)=> secLabel? option.name + ' ' + option[secLabel]: option.name}
      getOptionValue ={(option)=>option.id}
      noOptionsMessage={() => <IntlMessages id="global.noOptions" />} 
      isMulti={isMulti}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#FFF3E0',
          primary: '#F6933C',
          primary50: '#FFF3E0'
        },
      })}
    />
  );
};

export default CustomSelect;
