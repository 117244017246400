import {
  IoMdInfinite,
  IoMdCube,
  IoMdCard,
  IoIosArrowForward,
  IoMdArrowDropup,
  IoMdArrowDropdown,
  IoMdMegaphone,
} from "react-icons/io";
import {
  IoSettingsSharp,
  IoCubeSharp,
  IoHomeOutline,
  IoWarningOutline,
  IoCloseOutline,
  IoChevronDown,
  IoChevronUp,
  IoWaterOutline,
  IoFastFoodOutline,
} from "react-icons/io5";
import {
  BsPlayBtn,
  BsDot,
  BsPatchExclamationFill,
  BsShare,
  BsThreeDotsVertical,
  BsFileEarmarkTextFill,
  BsBank,
  BsQuestionCircle,
  BsFillHeartFill,
  BsBack,
  BsChevronRight,
  BsChevronLeft,
  BsArrowLeft,
  BsArrowRight,
  BsFillCreditCardFill,
  BsCash,
  BsFuelPump,
} from "react-icons/bs";
import {
  HiOutlineCurrencyYen,
  HiOutlineUser,
  HiUser,
  HiOutlineCurrencyDollar,
  HiOutlineTrash,
  HiLocationMarker,
  HiPhone,
} from "react-icons/hi";
import { HiArrowsRightLeft, HiOutlineArrowTrendingDown } from "react-icons/hi2";
import {
  FiChevronDown,
  FiChevronUp,
  FiLinkedin,
  FiDownload,
  FiMapPin,
  FiPhone,
  FiHome,
  FiBookmark,
  FiGrid,
  FiSearch,
  FiClock,
} from "react-icons/fi";
import {
  FaTwitterSquare,
  FaInstagramSquare,
  FaFacebookSquare,
  FaLinkedin,
  FaYoutubeSquare,
  FaSnapchatSquare,
  FaWhatsappSquare,
  FaGripLines,
  FaUser,
  FaUserAlt,
  FaHourglassStart,
  FaReceipt,
  FaCarSide,
  FaHandHoldingMedical,
  FaRegMoneyBillAlt,
  FaPen,
  FaListUl,
  FaRegStar,
} from "react-icons/fa";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import {
  AiOutlineSearch,
  AiFillCheckCircle,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineQuestionCircle,
  AiFillInfoCircle,
  AiOutlineLoading3Quarters,
  AiFillHome,
  AiFillStar,
  AiOutlinePlusSquare,
  AiOutlineMinusSquare,
} from "react-icons/ai";
import {
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdMail,
  MdDone,
  MdOutlinePhonelink,
  MdOutlineFavoriteBorder,
  MdOutlineFavorite,
  MdOutlineShoppingCart,
  MdOutlineNotificationsNone,
  MdOutlineOndemandVideo,
  MdOutlineAccountBalanceWallet,
  MdSend,
  MdModeEditOutline,
  MdReviews,
  MdAccountBalanceWallet,
  MdLocationOn,
  MdGTranslate,
  MdMessage,
  MdLanguage,
  MdOutlineDateRange,
  MdClose,
  MdQrCode2,
  MdOutlineLogout,
  MdOutlineMail,
  MdAssignmentReturn,
  MdCancel,
  MdEdit,
  MdApartment,
  MdStars,
  MdOutlineLocalPharmacy,
  MdDesignServices,
  MdOutlineCategory,
  MdGridView,
  MdOutlineHome,
} from "react-icons/md";
import {
  BiChat,
  BiPlus,
  BiDotsVerticalRounded,
  BiTrashAlt,
  BiCheckCircle,
  BiDollar,
  BiPencil,
  BiTrash,
  BiBuildingHouse,
} from "react-icons/bi";
import { GiCube, GiReturnArrow, GiWorld } from "react-icons/gi";
import { GoFileMedia, GoPlus } from "react-icons/go";
import {
  RiMailCheckFill,
  RiErrorWarningLine,
  RiArrowDownSLine,
  RiFlagLine,
  RiFileTransferLine,
  RiMedicineBottleLine,
  RiShoppingBag3Line,
  RiUserShared2Line,
  RiShoppingCart2Line,
  RiEBike2Fill,
} from "react-icons/ri";
import { VscGlobe, VscIssueDraft } from "react-icons/vsc";
import { GrCurrency } from "react-icons/gr";
import {} from "react-icons/fc";
import {} from "react-icons/ti";
import { BsCheckCircleFill, BsLink } from "react-icons/bs";
import {
  ImPlay2,
  ImFolderDownload,
  ImSearch,
  ImCheckboxUnchecked,
  ImArrowLeft2,
  ImArrowRight2,
} from "react-icons/im";
import { CgNotes, CgSandClock } from "react-icons/cg";
import {
  TbFileCertificate,
  TbBrandTwitter,
  TbTruckReturn,
} from "react-icons/tb";
import { TiBusinessCard } from "react-icons/ti";

import { RxCopy } from "react-icons/rx";

// exports
export const LangIcon = VscGlobe;
export const VisibilityIcon = MdOutlineVisibility;
export const VisibilityOffIcon = MdOutlineVisibilityOff;
export const Twitter = FaTwitterSquare;
export const Instagram = FaInstagramSquare;
export const Facebook = FaFacebookSquare;
export const Youtube = FaYoutubeSquare;
export const Snapchat = FaSnapchatSquare;
export const Whatsapp = FaWhatsappSquare;

export const Linkedin = FaLinkedin;
export const SearchIcon = FiSearch;
export const DownArrow = FiChevronDown;
export const UpArrow = FiChevronUp;
export const FavIcon = MdOutlineFavoriteBorder;
export const FavFilledIcon = MdOutlineFavorite;
export const CartIcon = RiShoppingCart2Line;
export const NotiIcon = MdOutlineNotificationsNone;
export const DotIcon = BsDot;
export const NoDataIcon = BsPatchExclamationFill;
export const CheckIcon = AiFillCheckCircle;
export const ShareIcon = BsShare;
export const FolderIcon = ImFolderDownload;
export const DownloadIcon = FiDownload;
export const CommentsIcon = BiChat;
export const DotsIcon = BiDotsVerticalRounded;
export const SendIcon = MdSend;
export const OutlineCurrencyYen = HiOutlineCurrencyYen;
//Authentication
export const MailIcon = MdMail;
export const CheckCircleFillIcon = BsCheckCircleFill;
export const MailCheckFillIcon = RiMailCheckFill;

//viewProduct
export const ErrorWarningLineIcon = RiErrorWarningLine;
export const PlayIcon = ImPlay2;
export const DoneIcon = MdDone;
export const PlayBtnIcon = BsPlayBtn;
export const NotesIcon = CgNotes;
export const MdInfiniteIcon = IoMdInfinite;
export const OutlinePhonelinkIcon = MdOutlinePhonelink;
export const FileCertificateIcon = TbFileCertificate;
export const FillCheckCircleIcon = AiFillCheckCircle;

//Instructor details
export const LinkIcon = BsLink;
export const LinkedinIcon = FiLinkedin;
export const BrandTwitterIcon = TbBrandTwitter;

//Account
export const MdCube = IoMdCube;
export const FillHeartFill = BsFillHeartFill;
export const Reviews = MdReviews;
export const AccountBalanceWallet = MdAccountBalanceWallet;
export const LocationOn = MdLocationOn;
export const MdCard = IoMdCard;
export const UserAlt = FaUserAlt;
export const Language = MdLanguage;
export const GTranslate = MdGTranslate;
export const FillInfoCircle = AiFillInfoCircle;
export const Message = MdMessage;
export const IosArrowForward = IoIosArrowForward;
export const OutlineDateRange = MdOutlineDateRange;
export const Close = MdClose;
export const Pencil = BiPencil;
export const HourglassStart = FaHourglassStart;
export const Receipt = FaReceipt;
export const Cube = GiCube;
export const CarSide = FaCarSide;
export const TruckReturn = TbTruckReturn;
export const AssignmentReturn = MdAssignmentReturn;
export const Back = BsBack;
export const HandHoldingMedical = FaHandHoldingMedical;
export const Cancel = MdCancel;
export const ReturnArrow = GiReturnArrow;
export const IssueDraft = VscIssueDraft;
export const Checkbox = ImCheckboxUnchecked;
export const RegMoneyBillAlt = FaRegMoneyBillAlt;
export const Pen = FaPen;
export const FillHome = AiFillHome;
export const Plus = GoPlus;
export const Edit = MdEdit;
export const Trash = BiTrash;
export const LocationMarker = HiLocationMarker;
export const Phone = HiPhone;
export const HomeOutline = IoHomeOutline;
export const Apartment = MdApartment;
export const BuildingHouse = BiBuildingHouse;
export const World = GiWorld;
export const ListUl = FaListUl;
export const ListIcon = FaListUl;
export const Grid = FiGrid;
export const OutlineAccountBalanceWallet = MdOutlineAccountBalanceWallet;
export const Stars = MdStars;
export const MdMegaphone = IoMdMegaphone;
export const ArrowsRightLeft = HiArrowsRightLeft;

//Instructor
export const OutlineOndemandVideo = MdOutlineOndemandVideo;
export const ThreeDotsVertical = BsThreeDotsVertical;
// export const Plus = BiPlus;
export const Search = ImSearch;
export const ArrowDownSLine = RiArrowDownSLine;
export const TrashAlt = BiTrashAlt;
export const OutlinePlusCircle = AiOutlinePlusCircle;
export const FileEarmarkTextFill = BsFileEarmarkTextFill;
export const ModeEditOutline = MdModeEditOutline;
export const GripLines = FaGripLines;
export const Bank = BsBank;
export const User = FaUser;
export const BusinessCard = TiBusinessCard;
export const CheckCircle = BiCheckCircle;
export const FlagLine = RiFlagLine;
export const FileTransferLine = RiFileTransferLine;
export const Dollar = BiDollar;
export const QuestionCircle = BsQuestionCircle;
export const OutlineQuestionCircle = AiOutlineQuestionCircle;
// new
// export const UserIcon = HiOutlineUser;
export const UserIcon = RiUserShared2Line;
export const Loading = AiOutlineLoading3Quarters;
export const SandClockIcon = CgSandClock;
export const QrIcon = MdQrCode2;
export const LogoutIcon = MdOutlineLogout;
export const SettingsIcon = IoSettingsSharp;
export const OrdersIcon = IoCubeSharp;
export const UserSolidIcon = HiUser;
export const MapIcon = FiMapPin;
export const EmailIcon = MdOutlineMail;
export const PhoneIcon = FiPhone;
export const AddressIcon = FiHome;
export const CurrencyIcon = HiOutlineCurrencyDollar;
export const DeleteIcon = HiOutlineTrash;
export const ChevronRightIcon = BsChevronRight;
export const ChevronLeftIcon = BsChevronLeft;
export const ArrowUpIcon = IoMdArrowDropup;
export const ArrowDownIcon = IoMdArrowDropdown;
export const BookmarkIcon = FiBookmark;
export const WarningIcon = IoWarningOutline;
export const ArrowLeftIcon = FaArrowLeft;
export const ArrowRightIcon = FaArrowRight;
export const CreditCardIcon = BsFillCreditCardFill;
export const CashIcon = BsCash;
export const WalletIcon = MdAccountBalanceWallet;
export const StarIcon = AiFillStar;
export const PlainStarIcon = FaRegStar;
export const PlusIcon = AiOutlinePlusSquare;
export const ArrowTrendingDown = HiOutlineArrowTrendingDown;
export const ArrowLeft2 = ImArrowLeft2;
export const ArrowRight2 = ImArrowRight2;
export const MinusIcon = AiOutlineMinusSquare;
export const CloseIcon = IoCloseOutline;
export const ChevronDownIcon = IoChevronDown;
export const ChevronUpIcon = IoChevronUp;
export const FuelIcon = BsFuelPump;
export const PharmacyIcon = RiMedicineBottleLine;
export const WaterIcon = IoWaterOutline;
export const ShoppingIcon = RiShoppingBag3Line;
export const FoodIcon = IoFastFoodOutline;
export const ClockIcon = FiClock;
export const BikeIcon = RiEBike2Fill;
export const RxCopyIcon = RxCopy;
export const ServicesIcon = MdDesignServices;
export const CategoriesIcon = MdOutlineCategory;
export const OtherIcon = MdGridView;
export const HomeIcon = MdOutlineHome;
