import { actionTypes } from "./actions";

const initState = {
  remoteConfig: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    config: null,
  },
};

const remoteConfigRequest = (state, payload) => ({
  ...state,
  remoteConfig: { isLoading: true },
});
const remoteConfigRequestSuccess = (state, payload) => {
  return {
    ...state,
    remoteConfig: {
      isLoading: false,
      isSuccess: true,
      // successMessage: payload?.message,
      successMessage: null,
      isFailed: false,
      error: null,
      config: payload,
    },
  };
};
const remoteConfigRequestFailed = (state, payload) => ({
  ...state,
  remoteConfig: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

const serviceSwitcher = (state, payload) => ({
  ...state,
  remoteConfig: {
    ...state.remoteConfig,
    config: {
      ...state.remoteConfig.config,
      default_service_id: payload, // Update default_service with payload
    },
  },
});

export default function remoteConfigReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actionTypes.REMOTE_CONFIG_REQUEST:
      return remoteConfigRequest(state, payload);
    case actionTypes.REMOTE_CONFIG_REQUEST_SUCCESS:
      return remoteConfigRequestSuccess(state, payload);
    case actionTypes.REMOTE_CONFIG_REQUEST_FAILED:
      return remoteConfigRequestFailed(state, payload);
    case actionTypes.SERVICE_SWITCHER:
      return serviceSwitcher(state, payload); // Handle SERVICE_SWITCHER action
    default:
      return state;
  }
}
