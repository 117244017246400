import React, { useEffect, useState, Fragment } from "react";
import IntlMessages from "components/Utils/intlMessages";
import Messages from "components/Navbars/i18nStrings";
import { useHistory } from "react-router-dom"; // Import useHistory
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/remoteConfig/actions";
import updateLocalStorage from "utils/helpers/updateLocalStorage";

const Services = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { services, isLoading } = useSelector(
    (state) => state.Services.districtServices
  );
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );

  useEffect(() => {
    if (selectedService && !isLoading && services?.length > 0) {
      const selectedServiceItem = services?.find(
        (service) => service.id == selectedService
      );
      if (selectedServiceItem) {
        updateLocalStorage("selectedServiceName", selectedServiceItem.name);
      }
    }
    const handleStorageUpdate = () => {
      const newSelectedService = localStorage.getItem("selectedService");
      setSelectedService(newSelectedService);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, [selectedService, isLoading, services]);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleServiceClick = (serviceId) => {
    updateLocalStorage("selectedService", serviceId);
    const selectedServiceItem = services.find(
      (service) => service.id === serviceId
    );
    if (selectedServiceItem) {
      updateLocalStorage("selectedServiceName", selectedServiceItem.name);
    }
    // dispatch(actions.serviceSwitcher(serviceId));
    window.location.href = "/home";
  };

  return (
    <>
      <div className={`w-full overflow-x-auto flex gap-1`}>
        {!isLoading &&
          services?.map((service, index) => (
            <Fragment key={index}>
              <button
                disabled={isServiceDetailsLoading}
                onClick={() => {
                  if (isServiceDetailsLoading) {
                    return;
                  } else {
                    handleServiceClick(service.id);
                  }
                }}
                className={`flex  flex-shrink-0 items-center px-4 py-2 rounded-lg text-base font-inter-semibold  ${
                  index == 0 && selectedService != service.id ? "!ps-0" : ""
                } ${service.mini_icon ? "!gap-2" : "gap-0"} ${
                  selectedService == service.id
                    ? "text-primary-500 bg-primary-50 border border-primary-200"
                    : "text-gray-500"
                } ${isServiceDetailsLoading ? "cursor-not-allowed" : ""}`}
              >
                <img src={service.mini_icon} className="h-6" />
                {service.name}
              </button>
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default Services;
