import React, { useEffect } from "react";
import { useField, Field, ErrorMessage, useFormikContext } from "formik";
import IntlMessages from "components/Utils/intlMessages";

const styles = {
  label:
    "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold cursor-pointer",
  field:
    "border border-gray-80 text-secondary-950 focus:outline-none focus:shadow-outline py-1 w-full rounded appearance-none",
  option: "flex gap-2 items-center",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-secondary-950 bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-2 w-full rounded appearance-none border-error-900",
};

function RadioTextOption(props) {
  const { label, errors, touched, name, options, ...rest } = props;
  const [field, meta] = useField(name);
  const formik = useFormikContext(); // Access the formik context

  const handleClearField = () => {
    formik.setFieldValue(name, ""); // Clear the field value by setting it to an empty string
  };

  useEffect(() => {
    if (field.value === undefined) {
      formik.setFieldValue(name, ""); // Initialize the field value as an empty string if it's undefined
    }
  }, [field.value, formik.setFieldValue, name]);

  return (
    <div className="w-full flex flex-col py-5 gap-3">
      <div className="flex items-center justify-between">
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        <span
          onClick={handleClearField}
          className="text-primary-400 cursor-pointer"
        >
          <IntlMessages id="global.clear" />
        </span>
      </div>

      <Field
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
      >
        {({ field }) => {
          return options?.map((option, index) => {
            const isChecked = field.value === option.id;

            return (
              <div className={styles.option} key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  {...field}
                  {...rest}
                  style={{ position: "absolute", opacity: 0 }} // Hide the radio button visually
                  value={option.id}
                  checked={isChecked}
                  onChange={() => {
                    if (!isChecked) {
                      formik.setFieldValue(name, option.id);
                    }
                  }}
                />
                <label
                  className={styles.label + " " + "!font-inter-regular"}
                  htmlFor={option.id}
                >
                  {option.name}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default RadioTextOption;
