import React from "react";
import { Field, ErrorMessage } from "formik";
const styles = {
  label: "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-50 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50",
};
function TextArea(props) {
  const { label, errors, touched, name, className, ...rest } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field
        className={
          touched && errors
            ? styles.fieldError + " " + className
            : styles.field + " " + className
        }
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}
export default TextArea;
