import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

export const productsRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.PRODUCTS_REQUEST),
    switchMap((action) =>
      Api.getProducts(action.payload)
        .then((response) => {
          return actions.productsRequestSuccess(response);
        })
        .catch((error) => actions.productsRequestFailed(error))
    )
  );

export default [productsRequest];
