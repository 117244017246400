import { actionTypes } from "./actions";

const initState = {
  settings: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    config: null,
  },
};

const settingsRequest = (state, payload) => ({
  ...state,
  settings: { isLoading: true },
});
const settingsRequestSuccess = (state, payload) => ({
  ...state,
  settings: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    config: payload,
  },
});
const settingsRequestFailed = (state, payload) => ({
  ...state,
  settings: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
export default function settingsReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.SETTINGS_REQUEST:
      return settingsRequest(state, payload);
    case actionTypes.SETTINGS_REQUEST_SUCCESS:
      return settingsRequestSuccess(state, payload);
    case actionTypes.SETTINGS_REQUEST_FAILED:
      return settingsRequestFailed(state, payload);
    default:
      return state;
  }
}
