import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { cls } from "utils/helpers/cls";

const classes = {
  base: "focus:outline-none transition ease-in-out duration-300",
  disabled: "cursor-not-allowed",
  loading:
    "flex items-center justify-center gap-2 opacity-50 cursor-not-allowed",
  pill: "rounded-full",
  danger: "bg-error-900",
  icon: "flex items-center justify-center w-10 h-8 !p-0 ",
  size: {
    auto: "",
    small: "px-6 py-[1px] text-xs leading-2",
    normal: "px-6 py-[5px] text-xs leading-2",
    medium: "px-6 py-[7px] text-base leading-4",
    large: "px-6 py-[9px] text-base leading-4",
    "x-large": "px-6 py-3 text-2xs leading-6",
    "2x-large": "px-6 py-4 text-2xs leading-6",
  },
  variant: {
    text: "text-primary-500 flex items-center gap-1 p-0 ltr:font-inter-medium rtl:font-semibold",
    solid:
      "text-white border border-primary-500 hover:border-primary-500 active:border-primary-500 bg-primary-500 hover:bg-primary-400 active:bg-primary-400 focus:ring-2 focus:ring-primary-50 focus:ring-opacity-20 rounded-lg disabled:bg-gray-250 disabled:border-gray-250 ltr:font-inter-medium rtl:font-semibold",
    outlined:
      "text-gray-700 border border-gray-300 hover:border-primary-400 hover:text-primary-400 active:border-primary-400 focus:ring-2 focus:ring-primary-50 focus:ring-opacity-20  rounded-lg disabled:text-gray-7000 disabled:border-gray-250 ltr:font-inter-regular rtl:font-normal",
    gray: "text-gray-700 border border-gray-80 hover:border-primary-400 hover:text-primary-400 active:border-primary-100 focus:ring-2 focus:ring-gray-80 focus:ring-opacity-20  rounded-lg disabled:text-gray-7000 disabled:border-gray-250 ltr:font-inter-regular rtl:font-normal",
    danger:
      "bg-error-600 border border-error-600 text-white focus:ring-2 focus:ring-error-50 focus:ring-opacity-20 rounded-lg disabled:text-white disabled:border-gray-250 ltr:font-inter-medium rtl:font-semibold",
    ghost:
      "text-gray-700 px-0 hover:text-primary-400  disabled:text-gray-7000 ltr:font-inter-medium rtl:font-semibold",
  },
  auto: "px-[9px]",
};

const Button = forwardRef(
  (
    {
      children,
      type = "button",
      className,
      variant = "solid",
      size = "normal",
      pill,
      disabled = false,
      loading = false,
      icon = false,
      auto = false,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      type={type}
      className={cls(`
                ${classes.base}
                ${classes.size[size]}
                ${classes.variant[variant]}
                ${pill && classes.pill}
                ${disabled && classes.disabled}
                ${loading && classes.loading}
                ${icon && classes.icon}
                ${auto && classes.auto}
                ${className}
            `)}
      {...props}
    >
      {loading && (
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-5 h-5 border-2 border-current rounded-full animate-spin"
        ></div>
      )}{" "}
      {children}
    </button>
  )
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.oneOf(["submit", "button"]),
  className: PropTypes.string,
  pill: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf([
    "text",
    "solid",
    "outlined",
    "gray",
    "danger",
    "ghost",
  ]),
  size: PropTypes.oneOf([
    "auto",
    "small",
    "normal",
    "large",
    "x-large",
    "2x-large",
  ]),
};

export default Button;
