import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";

const styles = {
  label: "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
};

function HashedInput(props) {
  const { name, errors, touched, label, ...rest } = props;
  const [hashedValue, setHashedValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    const hashed = Array(value.length + 1).join("#");
    setHashedValue(hashed);
  };

  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field id={name} name={name}>
        {(formik) => {
          const { field } = formik;
          return (
            <div className="relative">
              <input
                className={touched && errors ? styles.fieldError : styles.field}
                onChange={(e) => {
                  field.onChange(e);
                  handleChange(e);
                }}
                {...field}
                {...rest}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400">
                {hashedValue}
              </div>
            </div>
          );
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default HashedInput;
