import { actionTypes } from "./actions";

const initState = {
  serviceBranches: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    branches: [],
  },
};

const branchesRequest = (state, payload) => ({
  ...state,
  serviceBranches: { isLoading: true },
});
const branchesRequestSuccess = (state, payload) => ({
  ...state,
  serviceBranches: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    total_pages: payload?.meta?.last_page,
    branches: payload?.data,
  },
});
const branchesRequestFailed = (state, payload) => ({
  ...state,
  serviceBranches: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

const toggleItemFav = (state, payload) => {
  const updatedBranches = state.serviceBranches.branches.map((branch) => {
    if (branch.id === payload.id) {
      return {
        ...branch,
        is_favorite: !branch.is_favorite,
      };
    }
    return branch;
  });

  return {
    ...state,
    serviceBranches: {
      ...state.serviceBranches,
      branches: updatedBranches,
    },
  };
};
const resetBranches = (state) => ({
  ...state,
  serviceBranches: {
    ...state.serviceBranches,
    branches: [],
  },
});
export default function branchesReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.BRANCHES_REQUEST:
      return branchesRequest(state, payload);
    case actionTypes.BRANCHES_REQUEST_SUCCESS:
      return branchesRequestSuccess(state, payload);
    case actionTypes.BRANCHES_REQUEST_FAILED:
      return branchesRequestFailed(state, payload);
    case actionTypes.TOGGLE_ITEM_FAV:
      return toggleItemFav(state, payload);
    case actionTypes.RESET_BRANCHES:
      return resetBranches(state);
    default:
      return state;
  }
}
