import { combineEpics } from "redux-observable";
import authEpics from "./auth/epics";
import UserEpics from "./User/epics";
import orderEpics from "./order/epics";
import settingsEpics from "./settings/epics";
import remoteConfigEpics from "./remoteConfig/epics";
import userprofileEpics from "./userprofile/epics";
import selectedServiceEpics from "./selectedService/epics";
import branchesEpics from "./branches/epics";
import servicesEpics from "./services/epics";
import productsEpics from "./products/epics";
const epics = [
  ...authEpics,
  ...UserEpics,
  ...orderEpics,
  ...settingsEpics,
  ...remoteConfigEpics,
  ...userprofileEpics,
  ...selectedServiceEpics,
  ...branchesEpics,
  ...servicesEpics,
  ...productsEpics,
];
const rootEpic = combineEpics(...epics);
export default rootEpic;
