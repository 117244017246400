import axios from "utils/plugins/ApiClient";
import { useQuery } from "react-query";

// Get Static Pages
const fetchStaticPages = async ({ queryKey }) => {
  const { category } = queryKey[1];
  const { data } = await axios.get(`/static-pages?category=${category}`);
  return data.response.data;
};
export const useGetStaticPages = (props) => {
  const queries = {
    category: props?.category || "",
  };
  return useQuery(["static-pages", queries], fetchStaticPages);
};

// Get Information
const fetchInformation = async (id) => {
  const { data } = await axios.get(`/static-pages/${id}`);
  return data.response.data;
};
export const useGetInformation = (id) => {
  return useQuery(["information", id], () => fetchInformation(id));
};
