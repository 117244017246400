import React, { useState } from "react";
import axios from "axios";

import { Edit } from "assets/icons";
import { toast } from "react-toastify";

const UploadImage = (props) => {
  const { label, imageSrc, imageAlt, onSelectImage } = props;
  const [previewPhoto, setPreviewPhoto] = useState(null);

  const handleSelectProfilePhoto = (e) => {
    const imageFile = e.target.files;
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile[0]);
      axios
        .post(
          `${process.env.REACT_APP_BASE_BE_API}/upload-file/upload`,
          formData
        )
        .then((response) => {
          setPreviewPhoto(URL.createObjectURL(imageFile[0]));
          onSelectImage(response.data.response.data.path);
        })
        .catch((error) => {
          toast.error(error.message);
          // console.log(error);
        });
    }
  };

  return (
    <div className="mb-4">
      <label className="text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold pb-1 block">
        {label}
      </label>

      <div className="relative inline-block">
        <img
          className="w-40 h-40 object-cover rounded-full border border-grey  me-2 "
          src={previewPhoto ?? imageSrc}
          alt={imageAlt}
        />
        <div className="absolute inset-0">
          <label
            htmlFor="file"
            className="w-full h-full cursor-pointer block relative"
          >
            <span className="absolute bottom-0 start-0 bg-primary-400 p-2 block rounded-full">
              <Edit size={25} className="text-white" />
            </span>
          </label>
          <input
            id="file"
            hidden
            type="file"
            onChange={handleSelectProfilePhoto}
            accept="image/*"
          />
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
