import React, { useState, useEffect } from "react";
import Modal from "components/Modals/Modal";
import Button from "components/Buttons/Button";
import MapContainer from "./MapContainer";
import Messages from "./i18nStrings";
import IntlMessages from "components/Utils/intlMessages";
import { GoogleApiWrapper } from "google-maps-react";
import { MapIcon } from "assets/icons";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const LocationModal = ({
  handleCloseModal,
  google,
  setLocation,
  selectedAddress,
  setSelectedAddress,
  location,
  addressData,
}) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [districtId, setDistrictId] = useState(null);

  const { data } = useQuery(
    ["searchData", searchValue],
    async () => {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_BE_API}/map/search?search=${searchValue}`
      );
      const json = await response.json();
      setIsLoading(false);
      return json.response.data;
    },
    {
      enabled: !!searchValue,
    }
  );

  useEffect(() => {
    let isMounted = true; // Set a flag to track if the component is mounted

    if (location && location.lat && location.lng) {
      fetchData(location.lat, location.lng, isMounted);
    }

    // Cleanup function to cancel async operations
    return () => {
      isMounted = false; // Update the flag when the component unmounts
    };
  }, [location]);

  const fetchData = async (lat, lng, isMounted) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_BE_API}/map/is-supported-location?latitude=${lat}&longitude=${lng}`
      );
      const json = await response.json();
      if (isMounted) {
        setDistrictId(json.response.data.district_id);
      }
      setIsLoading(false);
    } catch (error) {
      if (isMounted) {
        console.error("Error fetching data:", error);
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  const handleLocationChange = (selectedOption) => {
    if (selectedOption && selectedOption.location) {
      const selectedLocation = {
        lat: selectedOption.location.latitude,
        lng: selectedOption.location.longitude,
      };
      setLocation(selectedLocation);
      // setSelectedAddress(selectedOption.label);
    } else if (selectedOption === null) {
      // Clear the selected location
      setLocation({});
    }
  };
  // const handleChooseLocation = () => {
  //   localStorage.setItem("district_id", districtId);
  //   setTimeout(() => {
  //     history.push("/home");
  //   }, 200);
  // };

  const handleChooseLocation = () => {
    localStorage.setItem("district_id", districtId);
    window.location.href = "/home";
  };

  useEffect(() => {
    localStorage.removeItem("@matjr__statusModal");
  }, []);

  const options = data
    ? data.map((result) => ({
        value: result.name,
        label: (
          <div>
            <div>{result.name}</div>
            <div>{result.address}</div>
          </div>
        ),
        location: result.location,
        address: result.address,
      }))
    : [];

  return (
    <Modal
      title=""
      handleCloseModal={handleCloseModal}
      actionsPannel={false}
      size={"w-full lg:w-1/2"}
      color={"primary"}
      bg={"white"}
    >
      <Button variant="outlined" icon className="cursor-default">
        <MapIcon size="20" />
      </Button>
      <h1 className="text-gray-900 text-lg font-inter-semibold">
        {Messages.getFormatMessage("locationsModalTitle")}
      </h1>
      <p className="text-sm text-gray-600">
        {Messages.getFormatMessage("locationsModalDescription")}
      </p>
      <label
        htmlFor="location"
        className="relative block overflow-hidden text-inter-medium text-gray-700 mt-5"
      >
        {Messages.getFormatMessage("findChooseLocation")}
      </label>
      <Select
        id="location"
        onInputChange={(value) => setSearchValue(value)}
        options={options}
        onChange={handleLocationChange}
        value={options.find((option) => option.value === selectedAddress)}
        className="my-2 w-full border border-gray-300 rounded-md shadow-sm "
        placeholder={Messages.getFormatMessage("findChooseLocation")}
        noOptionsMessage={
          () =>
            searchValue && !isLoading // Check if there's a search value and not loading
              ? Messages.getFormatMessage("noResultsFound") // Show "No options found" if there's a search value and not loading
              : Object.keys(location).length === 0 // Otherwise, check if there's no selected location
              ? isLoading // Check if loading
                ? Messages.getFormatMessage("searching") // Show "Searching" if loading
                : Messages.getFormatMessage("startTyping") // Show "Start typing" if not loading and no selected location
              : null // Otherwise, don't show any message
        }
        getOptionLabel={(option) => option.label}
        isClearable={true}
      />

      <MapContainer
        google={google}
        location={location}
        addressData={addressData}
        onLocationChange={handleLocationChange}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
      <div className="flex items-center gap-5 my-5">
        <Button
          size="large"
          variant="gray"
          className="w-1/2"
          onClick={handleCloseModal}
        >
          <IntlMessages id="global.cancel" />
        </Button>

        <Button
          size="large"
          variant="solid"
          className="w-1/2"
          disabled={
            isLoading ||
            Object.keys(location).length === 0 ||
            !districtId ||
            districtId === "null"
          }
          onClick={handleChooseLocation}
        >
          {Object.keys(location).length !== 0 && !districtId
            ? Messages.getFormatMessage("locationNotSupported")
            : Object.keys(location).length === 0
            ? Messages.getFormatMessage("chooseLocation")
            : Messages.getFormatMessage("pickThisAddress")}
        </Button>
      </div>
    </Modal>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC84xaxrAOPzK27rKOefMTwwj0oqFhGtCI",
})(LocationModal);
