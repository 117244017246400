import { switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";
import HandleAxiosError from "utils/helpers/HandleAxiosError";

export const ordersRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_REQUEST),
    switchMap((action) =>
      Api.getOrders(action.payload)
        .then((response) => actions.ordersRequestSuccess(response))
        .catch((error) => actions.ordersRequestFailed(error))
    )
  );

function createOrderRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.CREATE_ORDER_REQUEST),
    switchMap((action) =>
      Api.createOrder(action.payload)
        .then((response) => {
          // console.log(response);
          if (response?.response?.status === 400) {
            return actions.createOrderFailed(response);
          } else if (response?.response?.status === 422) {
            return actions.createOrderFailed(response);
          } else {
            return actions.createOrderSuccess(response);
          }
        })
        .catch((error) => {
          // console.log(error);
          return actions.createOrderFailed(error);
        })
    )
  );
}

function updateOrderRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.UPDATE_ORDER_REQUEST),
    switchMap((action) =>
      Api.updateOrder(action.payload)
        .then((res) =>
          res ? actions.updateOrderSuccess(res) : actions.updateOrderFailed()
        )
        .catch(actions.updateOrderFailed)
    )
  );
}

function deleteOrderRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.DELETE_ORDER_REQUEST),
    switchMap((action) =>
      Api.deleteOrder(action.payload)
        .then(actions.deleteOrderSuccess)
        .catch(actions.deleteOrderFailed)
    )
  );
}

function addCouponRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.ADD_COUPON_REQUEST),
    switchMap((action) =>
      Api.addCoupon(action.payload)
        .then((res) =>
          res ? actions.addCouponSuccess(res) : actions.addCouponFailed()
        )
        .catch(actions.addCouponFailed)
    )
  );
}

export default [
  createOrderRequest,
  updateOrderRequest,
  ordersRequest,
  deleteOrderRequest,
  addCouponRequest,
];
