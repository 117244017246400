import { map, mapTo, mergeMap, of, concat, switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { createBrowserHistory } from "history";
import { PUBLIC_ROUTE, publicRoutes } from "route.constants";
import {
  values as _values,
  findIndex as _findIndex,
  forIn as _forIn,
} from "lodash";
import { getToken, clearToken } from "utils/helpers/utility";
import { store, dispatch } from "redux";
import { actions, actionTypes } from "./actions";
import { actions as userActions } from "../User/actions";
import * as Api from "./api";
import { matchPath } from "react-router";

// console.log("🚀 ~ file: epics.js ~ line 9 ~ actions", actions);

const history = createBrowserHistory();

function loginRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.LOGIN_REQUEST),
    switchMap((action) =>
      Api.loginUser(action.payload)
        .then((response) => {
          return actions.loginRequestSuccess(response);
        })
        .catch((error) => {
          // console.log(
          //   "🚀 ~ file: epics.js ~ line 23 ~ loginRequest ~ error",
          //   error
          // );

          return actions.loginRequestFailed(error);
        })
    )
  );
}
function verifyOtpRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.VERIFY_OTP_REQUEST),
    switchMap((action) =>
      Api.verifyOtp(action.payload)
        .then((response) => {
          return actions.verifyOtpRequestSuccess(response);
        })
        .catch((error) => {
          // console.log(
          //   "🚀 ~ file: epics.js ~ line 23 ~ loginRequest ~ error",
          //   error
          // );

          return actions.verifyOtpRequestFailed(error);
        })
    )
  );
}
function forgetPassword(action$) {
  return action$.pipe(
    ofType(actionTypes.FORGET_PASSWORD_REQUEST),
    switchMap((action) =>
      Api.forgetPassword(action.payload)
        .then((response) => actions.forgetPasswordSuccess(response))
        .catch((error) => actions.forgetPasswordFailed(error))
    )
  );
}

function resetPassword(action$) {
  return action$.pipe(
    ofType(actionTypes.RESET_PASSWORD_REQUEST),
    switchMap((action) =>
      Api.resetPassword(action.payload)
        .then((response) => actions.resetPasswordSuccess(response))
        .catch((error) => actions.resetPasswordFailed(error))
    )
  );
}

function registerRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.REGISTER_REQUEST),
    switchMap((action) =>
      Api.registerUser(action.payload)
        .then((data) => {
          // console.log(data);
          return actions.registerRequestSuccess(data);
        })
        .catch((error) => actions.registerRequestFailed(error))
    )
  );
}

function verifyEmailRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.VERIFY_EMAIL_REQUEST),
    switchMap((action) =>
      Api.verifyEmail(action.payload)
        .then((response) => {
          // if (response.user) {
          //     return actions.loginRequestSuccess(response)
          // }
          return actions.verifyEmailRequestSuccess({});
          // of(
          //   actions.loginRequestSuccess(response),
          //   actions.verifyEmailRequestSuccess({})
          // )
        })
        .catch((error) => actions.verifyEmailRequestFailed(error))
    )
  );
}

function logout(action$) {
  return action$.pipe(
    ofType(actionTypes.LOGOUT),
    switchMap(() => {
      var publicIndex = true;
      let authRoute = publicRoutes.filter((route) => route["auth"]);
      authRoute.map((route, index) => {
        if (publicIndex) {
          var isExist = matchPath(window.location.pathname, {
            path: route["path"],
          });
          if (isExist != null) {
            publicIndex = false;
          }
        }
      });
      // console.log(authRoute)
      // Object.keys(PUBLIC_ROUTE).map((key, index) => {
      //     if (publicIndex) {
      //         var isExist = matchPath(window.location.pathname, {
      //             path: PUBLIC_ROUTE[key],
      //         })
      //         if (isExist != null) {
      //             publicIndex = false;
      //         }
      //     }
      // });
      // call api
      clearToken();
      return of(
        actions.logoutSuccess({
          loading: false,
          shouldRedirect: publicIndex,
        })
      );
    })
  );
}

// function checkPresist(action$) {
//     return action$.pipe(
//         ofType('persist/REHYDRATE'),
//         switchMap((action) => {
//             const {token} = action.payload ? action.payload.User : {}
//             if (token) {
//                 return of(
//                     actions.checkAuthorizationSuccess({
//                         token: action.payload.User.token,
//                         profile: 'Profile',
//                     })
//                 )
//             }
//             // getToken().get('@matjr__token')
//             return of(actions.logout())
//         })
//     )
// }

export default [
  registerRequest,
  verifyEmailRequest,
  loginRequest,
  verifyOtpRequest,
  logout,
  // checkPresist,
  forgetPassword,
  resetPassword,
];
