import React, { useEffect, useState } from "react";
import Navbar from "components/Navbars/Navbar.js";
import LandingNavbar from "components/Navbars/LandingNavbar.js";
import ServicesNavbar from "components/Navbars/ServicesNavbar";
import CategoriesNavbar from "components/Navbars/CategoriesNavbar";
import Footer from "components/Footers/Footer.js";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { get as _get } from "lodash";
import Spinner from "components/Spinner";
import { useHistory } from "react-router-dom";

const MainLayout = ({ children }) => {
  const [faviconUrl, setFaviconUrl] = useState(null);
  const history = useHistory();
  const User = useSelector((state) => state.User);
  const isLoggedIn = _get(User, "token", false);
  const { config: settings, isLoading: isSettingsLoading } = useSelector(
    (state) => state.Settings.settings
  );
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  );
  const [useDefaultLocation, setUseDefaultLocation] = useState(false);

  useEffect(() => {
    const handleStorageUpdate = () => {
      const newUseDefaultLocation = localStorage.getItem(
        "use_default_location"
      );
      setUseDefaultLocation(newUseDefaultLocation);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, []);

  useEffect(() => {
    if (!isRemoteConfigLoading && remoteConfig) {
      setFaviconUrl(`${remoteConfig.fav_icon}?t=${Date.now()}`);
    }
  }, [isRemoteConfigLoading, remoteConfig]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (isLoggedIn || useDefaultLocation) {
        history.push("/home");
      }
    }
  }, [useDefaultLocation, isLoggedIn, location.pathname]);

  // Check if any data is loading
  const isLoading =
    isSettingsLoading ||
    isRemoteConfigLoading ||
    isServiceDetailsLoading ||
    isProfileLoading;

  if (isLoading) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const renderNavbars = () => {
    return (
      <>
        {isLoggedIn ? (
          <Navbar
            transparent
            settings={remoteConfig}
            isLoading={isRemoteConfigLoading}
            locale={locale}
          />
        ) : (
          <LandingNavbar
            transparent
            settings={remoteConfig}
            isLoading={isRemoteConfigLoading}
            locale={locale}
          />
        )}
        {remoteConfig?.enable_services_page &&
          location.pathname !== "/" &&
          localStorage.getItem("district_id") && <ServicesNavbar />}
        {serviceDetails?.show_categories_menu &&
          location.pathname !== "/" &&
          localStorage.getItem("district_id") && <CategoriesNavbar />}
      </>
    );
  };

  return (
    <>
      {!isRemoteConfigLoading && (
        <Helmet>
          {faviconUrl && <link rel="shortcut icon" href={faviconUrl} />}
          <title>{remoteConfig?.title}</title>
          <meta name="description" content={remoteConfig?.description} />
          {remoteConfig?.keywords && (
            <meta name="keywords" content={remoteConfig.keywords} />
          )}
        </Helmet>
      )}

      {renderNavbars()}
      <main className="md:min-h-screen">{children}</main>
      <Footer
        settings={settings}
        isLoading={isSettingsLoading}
        locale={locale}
      />
    </>
  );
};

export default MainLayout;
