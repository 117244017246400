import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import IntlMessages from "components/Utils/intlMessages";
import { actions } from "redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import listenForOutsideClick from "utils/helpers/listen-for-outside-clicks";
import { Link, useParams } from "react-router-dom";
import {
  UserIcon,
  LogoutIcon,
  SettingsIcon,
  OrdersIcon,
  UserSolidIcon,
  LocationOn,
  AccountBalanceWallet,
} from "assets/icons";
const UserOptionsDropdown = () => {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.User);
  const { profile } = useSelector((state) => state.Userprofile.userprofile);
  const { instructorId } = useParams();
  const loggedUser = User.user;
  const logout = () => {
    dispatch(actions.logout());
  };
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );
  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        className="flex gap-1 items-center"
      >
        <span className="flex gap-1 items-center">
          <img
            className={` w-5 h-5 object-cover rounded-full`}
            src={profile?.image}
            alt={profile?.name}
          />
          <span className="hidden lg:block text-base text-secondary-950 font-inter-regular">
            {" "}
            {profile?.name}
          </span>
        </span>

        {/* <UserIcon size={28} />
        <IntlMessages id="global.account&lists" /> */}
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-start p-4 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[10rem]"
        }
      >
        <span
          className={
            "text-base font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
          }
        ></span>
        {/* <h2 className="text-base leading-1 text-secondary-950 ltr:font-inter-medium rtl:font-medium">
          {loggedUser.name}
        </h2> */}
        <Link
          to="/account/my-orders"
          className={`flex items-center gap-1 text-base hover:text-primary  font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <OrdersIcon className="text-primary-400" size={16} />
          <IntlMessages id="global.myOrders" />
        </Link>
        <Link
          to="/account/my-address"
          className={`flex items-center gap-1 text-base hover:text-primary  font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <LocationOn className="text-primary-400" size={16} />
          <IntlMessages id="global.addresses" />
        </Link>
        <Link
          to="/account/my-wallet"
          className={`flex items-center gap-1 text-base hover:text-primary  font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <AccountBalanceWallet className="text-primary-400" size={16} />
          <IntlMessages id="global.wallet" />
        </Link>

        <Link
          to="/account/preferences"
          className={`flex items-center gap-1 text-base hover:text-primary  font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <SettingsIcon className="text-primary-400" size={16} />
          <IntlMessages id="global.settings" />
        </Link>
        <Link
          to="/account/profile"
          className={`flex items-center gap-1 text-base hover:text-primary  font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <UserSolidIcon className="text-primary-400" size={16} />
          <IntlMessages id="global.profile" />
        </Link>
        <button
          onClick={logout}
          className={`flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 `}
        >
          <LogoutIcon className="text-primary-400" size={16} />
          <IntlMessages id="global.logout" />
        </button>
      </div>
    </>
  );
};

export default UserOptionsDropdown;
