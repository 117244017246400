/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "components/Buttons/Button";
import IntlMessages from "components/Utils/intlMessages";
import LoginDropdown from "components/Dropdowns/LoginDropdown.js";
import LangDropdown from "components/Dropdowns/LangDropdown.js";
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown.js";
import CurrencyDropdown from "components/Dropdowns/CurrencyDropdown";
import actions from "redux/languageSwitcher/actions";
import { useDispatch, useSelector } from "react-redux";
import Search from "components/Search/SearchForm";
import { FavIcon, CartIcon, NotiIcon, UserIcon } from "assets/icons";
import { MapIcon, ChevronDownIcon } from "assets/icons";
import LocationModal from "components/Maps/LocationModal";
import AutocompleteSearchInput from "components/Search/AutocompleteSearchInput";
import LocationsDropdown from "components/Dropdowns/LocationsDropdown";
const options = [
  { label: "Apple", value: "apple" },
  { label: "Banana", value: "banana" },
  { label: "Cherry", value: "cherry" },
  { label: "Grape", value: "grape" },
  { label: "Orange", value: "orange" },
  { label: "Pineapple", value: "pineapple" },
];
import { get as _get } from "lodash";
import { actions as orderActions } from "redux/order/actions";
import CategoriesDropdown from "components/Dropdowns/CategoriesDropdown";
import FeaturedLinks from "components/Navbars/FeaturedLinks";
import { useGetCategories } from "apis/categories";
export default function CategoriesNavbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const handleToggleModal = () => setShowLocationsModal(!showLocationsModal);
  const User = useSelector((state) => state.User);
  const [location, setLocation] = useState({});
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  );
  const loggedUser = User.user;
  const isLoggedIn = _get(User, "token", false);

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const handleLocationClick = () => {
    if (isLoggedIn) {
      history.push("/account/my-address");
    } else {
      setShowLocationsModal(true);
    }
  };
  const [selectedAddress, setSelectedAddress] = useState(
    JSON.parse(localStorage.getItem("selectedAddress"))
  );
  useEffect(() => {
    if (selectedAddress) {
      // console.log("selectedAddress", selectedAddress);
      localStorage.setItem("selectedAddress", JSON.stringify(selectedAddress));
      // localStorage.setItem("location", JSON.stringify(location));
    }
  }, [selectedAddress]);
  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );

  useEffect(() => {
    const handleStorageUpdate = () => {
      const newSelectedService = localStorage.getItem("selectedService");
      setSelectedService(newSelectedService);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, []);
  const { data: categoriesData, isLoading } = useGetCategories({
    service_id: selectedService,
    is_tree: true,
    enabled: !isRemoteConfigLoading && !isProfileLoading,
    limit: 1000,
  });
  return (
    <>
      {!isLoading && categoriesData?.data?.length > 0 && (
        <nav className="hidden md:block bg-white border-b border-gray-80 z-50 text-xs py-2 text-secondary-950">
          <div className="relative container flex flex-col md:flex-row items-center justify-start md:justify-between">
            {/* {JSON.stringify(categoriesData)} */}
            <div className={`w-full flex items-center overflow-x-auto gap-16`}>
              {!isLoading && (
                <>
                  {categoriesData?.data?.length > 0 && (
                    <CategoriesDropdown
                      categories={categoriesData?.data}
                      isLoading={isLoading}
                    />
                  )}
                  {categoriesData?.meta?.featured?.length > 0 && (
                    <FeaturedLinks
                      links={categoriesData?.meta?.featured}
                      isLoading={isLoading}
                    />
                  )}
                </>
              )}
            </div>
            {selectedAddress &&
              !isRemoteConfigLoading &&
              !remoteConfig?.enable_services_page && (
                <Button
                  onClick={handleLocationClick}
                  className="flex items-center gap-3 "
                  variant="text"
                  size="auto"
                >
                  <MapIcon size={20} />
                  <span className="whitespace-nowrap font-inter-semibold">
                    {selectedAddress?.name
                      ? truncateText(selectedAddress?.name, 20)
                      : selectedAddress?.region
                      ? truncateText(selectedAddress?.region, 20)
                      : truncateText(selectedAddress?.streetAddress, 20)}
                  </span>
                  <ChevronDownIcon />
                </Button>
              )}
          </div>
        </nav>
      )}
      {showLocationsModal && (
        <LocationModal
          handleCloseModal={handleToggleModal}
          location={location}
          setLocation={setLocation}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
    </>
  );
}
