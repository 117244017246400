import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    account: {
      id: "account.account",
      defaultMessage: "Account",
    },
    myOrders: {
      id: "account.myOrders",
      defaultMessage: "My Orders",
    },
    myFavourites: {
      id: "account.myFavourites",
      defaultMessage: "My Favourites",
    },
    myReviews: {
      id: "account.myReviews",
      defaultMessage: "My Reviews",
    },
    myWallet: {
      id: "account.myWallet",
      defaultMessage: "My Wallet",
    },
    personalInformation: {
      id: "account.personalInformation",
      defaultMessage: "Personal Information",
    },
    myAddress: {
      id: "account.myAddress",
      defaultMessage: "My Address",
    },
    myCards: {
      id: "account.myCards",
      defaultMessage: "My Cards",
    },
    profile: {
      id: "account.profile",
      defaultMessage: "Profile",
    },
    settings: {
      id: "account.settings",
      defaultMessage: "Settings",
    },
    country: {
      id: "account.country",
      defaultMessage: "Country",
    },
    languages: {
      id: "account.languages",
      defaultMessage: "Languages",
    },
    matjrApp: {
      id: "account.matjrApp",
      defaultMessage: "Matjr App",
    },
    aboutMatjr: {
      id: "account.aboutAbout",
      defaultMessage: "About App",
    },
    contactUs: {
      id: "account.contactUs",
      defaultMessage: "Contact Us",
    },
    support: {
      id: "account.support",
      defaultMessage: "Support",
    },
    current: {
      id: "account.current",
      defaultMessage: "Current",
    },
    completed: {
      id: "account.completed",
      defaultMessage: "Completed",
    },
    rejectedLabel: {
      id: "account.rejectedLabel",
      defaultMessage: "Rejected",
    },
    cancellations: {
      id: "account.cancellations",
      defaultMessage: "Cancellations",
    },
    searchProduct: {
      id: "account.searchProduct",
      defaultMessage: "Search for product name or brand",
    },
    moreOrders: {
      id: "account.moreOrders",
      defaultMessage: "See more Orders",
    },
    draft: {
      id: "account.draft",
      defaultMessage: "Draft",
    },
    pending: {
      id: "account.pending",
      defaultMessage: "pending",
    },
    processing: {
      id: "account.processing",
      defaultMessage: "processing",
    },
    shipped: {
      id: "account.shipped",
      defaultMessage: "shipped",
    },
    outForDelivery: {
      id: "account.outForDelivery",
      defaultMessage: "out_for_delivery",
    },
    delivered: {
      id: "account.delivered",
      defaultMessage: "delivered",
    },
    inReturn: {
      id: "account.inReturn",
      defaultMessage: "in_return",
    },
    returnReceived: {
      id: "account.returnReceived",
      defaultMessage: "return_received",
    },
    returned: {
      id: "account.returned",
      defaultMessage: "returned",
    },
    onHold: {
      id: "account.onHold",
      defaultMessage: "on_hold",
    },
    rejected: {
      id: "account.rejected",
      defaultMessage: "rejected",
    },
    cancelled: {
      id: "account.cancelled",
      defaultMessage: "cancelled",
    },
    noOrders: {
      id: "account.noOrders",
      defaultMessage: "no orders available",
    },
    orderNumber: {
      id: "account.orderNumber",
      defaultMessage: "Order Number",
    },
    returnNumber: {
      id: "account.returnNumber",
      defaultMessage: "Return Number",
    },
    orderRate: {
      id: "account.orderRate",
      defaultMessage: "Order rate",
    },
    paymentMethod: {
      id: "account.paymentMethod",
      defaultMessage: "Payment method",
    },
    price: {
      id: "account.price",
      defaultMessage: "Price",
    },
    returnOrder: {
      id: "account.returnOrder",
      defaultMessage: "Return order",
    },
    cancelOrder: {
      id: "account.cancelOrder",
      defaultMessage: "cancel order",
    },
    ordersRate: {
      id: "account.ordersRate",
      defaultMessage: "Orders rate",
    },
    reviewLabel: {
      id: "account.reviewLabel",
      defaultMessage: "Review",
    },
    reviewPlaceholder: {
      id: "account.reviewPlaceholder",
      defaultMessage:
        "How was your experience with the product? What should others know before purchasing the product? How did you use the product?",
    },
    send: {
      id: "account.send",
      defaultMessage: "Send",
    },

    titleLabel: {
      id: "account.titleLabel",
      defaultMessage: "Title",
    },
    descriptionLabel: {
      id: "account.descriptionLabel",
      defaultMessage: "Description",
    },
    email: {
      id: "account.email",
      defaultMessage: "Email",
    },
    phone: {
      id: "account.phone",
      defaultMessage: "Phone",
    },
    address: {
      id: "account.address",
      defaultMessage: "Address",
    },
    mapLocation: {
      id: "account.mapLocation",
      defaultMessage: "Map Location",
    },
    findUs: {
      id: "account.findUs",
      defaultMessage: "Find Us",
    },

    fullNameLabel: {
      id: "auth.fullNameLabel",
      defaultMessage: "Full Name",
    },
    emailAddressLabel: {
      id: "auth.emailAddressLabel",
      defaultMessage: "Email",
    },

    orderDetail: {
      id: "account.orderDetail",
      defaultMessage: "Order Detail",
    },
    orderDate: {
      id: "account.orderDate",
      defaultMessage: "Order Date",
    },
    orderSummary: {
      id: "account.orderSummary",
      defaultMessage: "Order Summary",
    },
    product: {
      id: "account.product",
      defaultMessage: "Product",
    },
    store: {
      id: "account.store",
      defaultMessage: "Store",
    },
    paymentInformation: {
      id: "account.paymentInformation",
      defaultMessage: "Payment Information",
    },
    subtotal: {
      id: "account.subtotal",
      defaultMessage: "Subtotal",
    },
    deliveryFee: {
      id: "account.deliveryFee",
      defaultMessage: "Delivery Fee",
    },
    discountCoupon: {
      id: "account.discountCoupon",
      defaultMessage: "Discount Coupon",
    },
    walletBalance: {
      id: "account.walletBalance",
      defaultMessage: "Wallet Balance",
    },
    total: {
      id: "account.total",
      defaultMessage: "Total",
    },
    shippingInformation: {
      id: "account.shippingInformation",
      defaultMessage: "Shipping Information",
    },
    addressType: {
      id: "account.addressType",
      defaultMessage: "Address type",
    },
    returnProducts: {
      id: "account.returnProducts",
      defaultMessage: "Return Products",
    },

    trackOrder: {
      id: "account.trackOrder",
      defaultMessage: "Track Order",
    },
    returnProduct: {
      id: "account.returnProduct",
      defaultMessage: "Return Product",
    },

    selectItems: {
      id: "account.selectItems",
      defaultMessage: "Select the items you want to return",
    },
    refundMethodButton: {
      id: "account.refundMethodButton",
      defaultMessage: "Continue to Refund Method",
    },

    refundMethod: {
      id: "account.refundMethod",
      defaultMessage: "Refund method",
    },
    chooseRefundMethod: {
      id: "account.chooseRefundMethod",
      defaultMessage: "Choose a Refund method",
    },
    walletMethod: {
      id: "account.walletMethod",
      defaultMessage: "Wallet",
    },
    originalPaymentMethod: {
      id: "account.originalPaymentMethod",
      defaultMessage: "Original Payment method",
    },
    returnPickupButton: {
      id: "account.returnPickupButton",
      defaultMessage: "Continue to Return Pickup",
    },

    returnPickup: {
      id: "account.returnPickup",
      defaultMessage: "Return Pickup",
    },
    howWillYouSend: {
      id: "account.howWillYouSend",
      defaultMessage: "How will you send a return request?",
    },
    pickupAddress: {
      id: "account.pickupAddress",
      defaultMessage: "Pickup Address",
    },
    changeAddress: {
      id: "account.changeAddress",
      defaultMessage: "Change Address",
    },
    dateAndTime: {
      id: "account.dateAndTime",
      defaultMessage: "Date and time",
    },
    summaryButton: {
      id: "account.summaryButton",
      defaultMessage: "Continue to Summary and Confirmation",
    },

    selectAddress: {
      id: "account.selectAddress",
      defaultMessage: "Select Address",
    },
    selectedSuccessfully: {
      id: "account.selectedSuccessfully",
      defaultMessage: "Address Selected Successfully",
    },

    phoneNumber: {
      id: "account.phoneNumber",
      defaultMessage: "Phone Number",
    },
    close: {
      id: "account.close",
      defaultMessage: "Close",
    },

    returnSummary: {
      id: "account.returnSummary",
      defaultMessage: "Return Summary",
    },
    confirmReturn: {
      id: "account.confirmReturn",
      defaultMessage: "Confirm Return",
    },
    totalRefund: {
      id: "account.totalRefund",
      defaultMessage: "Total Refund",
    },
    refundInformation: {
      id: "account.refundInformation",
      defaultMessage: "Refund Information",
    },
    returnOrders: {
      id: "account.returnOrders",
      defaultMessage: "Return Orders",
    },
    returnDetail: {
      id: "account.returnDetail",
      defaultMessage: "Return Details",
    },

    idRequired: {
      id: "account.idRequired",
      defaultMessage: "ID is required",
    },
    quantityRequired: {
      id: "account.quantityRequired",
      defaultMessage: "Quantity is required",
    },
    reasonRequired: {
      id: "account.reasonRequired",
      defaultMessage: "Reason is required",
    },
    commentLong: {
      id: "account.commentLong",
      defaultMessage: "Too long! The maximum character is 200 in Comment",
    },
    chooseOne: {
      id: "account.chooseOne",
      defaultMessage: "you should at least choose a product to return",
    },
    commentRequired: {
      id: "account.commentRequired",
      defaultMessage: "Comment is required",
    },
    noAddress: {
      id: "account.noAddress",
      defaultMessage: "no address available",
    },
    moreAddress: {
      id: "account.moreAddress",
      defaultMessage: "more addresses",
    },
    moreFavorite: {
      id: "account.moreFavorite",
      defaultMessage: "more favorite",
    },
    tax: {
      id: "account.tax",
      defaultMessage: "Tax",
    },
    cancelConfirmation: {
      id: "account.cancelConfirmation",
      defaultMessage: "Cancel Confirmation",
    },
    cancelDesc: {
      id: "account.cancelDesc",
      defaultMessage:
        'Order {id} will be cancelled, and refunded will made to original payment method if online payment, Do you want to change to "Cancel Order"',
    },
    confirm: {
      id: "account.confirm",
      defaultMessage: "Confirm",
    },
    cancel: {
      id: "account.cancel",
      defaultMessage: "Cancel",
    },
    printInvoice: {
      id: "account.printInvoice",
      defaultMessage: "Print Invoice",
    },
    submit: {
      id: "account.submit",
      defaultMessage: "Submit",
    },

    addNewAddress: {
      id: "account.addNewAddress",
      defaultMessage: "Add New Address",
    },
    addressTypeLabel: {
      id: "account.addressTypeLabel",
      defaultMessage: "Address Type",
    },
    addressName: {
      id: "account.addressName",
      defaultMessage: "Address Name",
    },
    EnterAddressName: {
      id: "account.EnterAddressName",
      defaultMessage: "Enter Address Name",
    },
    contactName: {
      id: "account.contactName",
      defaultMessage: "Contact Name",
    },
    enterContactName: {
      id: "account.enterContactName",
      defaultMessage: "Enter Contact Name",
    },
    phoneNumberLabel: {
      id: "account.phoneNumberLabel",
      defaultMessage: "Phone Number",
    },
    enterPhoneNumber: {
      id: "account.enterPhoneNumber",
      defaultMessage: "Enter Phone Number",
    },
    countryLabel: {
      id: "account.countryLabel",
      defaultMessage: "Country",
    },
    selectCountry: {
      id: "account.selectCountry",
      defaultMessage: "Select Country",
    },
    regionState: {
      id: "account.regionState",
      defaultMessage: "Region/state",
    },
    selectRegionState: {
      id: "account.selectRegionState",
      defaultMessage: "Select Region/state",
    },
    city: {
      id: "account.city",
      defaultMessage: "City",
    },
    selectCity: {
      id: "account.selectCity",
      defaultMessage: "Select City",
    },
    addressLabel: {
      id: "account.addressLabel",
      defaultMessage: "Address",
    },
    enterAddress: {
      id: "account.enterAddress",
      defaultMessage: "Enter Address",
    },
    buildingNumber: {
      id: "account.buildingNumber",
      defaultMessage: "Building Number",
    },
    enterBuildingNumber: {
      id: "account.enterBuildingNumber",
      defaultMessage: "Enter Building Number",
    },
    floor: {
      id: "account.floor",
      defaultMessage: "Floor",
    },
    enterFloor: {
      id: "account.enterFloor",
      defaultMessage: "Enter Floor",
    },
    apartmentNumber: {
      id: "account.apartmentNumber",
      defaultMessage: "Apartment Number",
    },
    enterApartmentNumber: {
      id: "account.enterApartmentNumber",
      defaultMessage: "Enter Apartment Number",
    },
    selectAddressOnMap: {
      id: "account.selectAddressOnMap",
      defaultMessage: "Select Address On Map",
    },
    postalCode: {
      id: "account.postalCode",
      defaultMessage: "Postal Code",
    },
    enterPostalCode: {
      id: "account.enterPostalCode",
      defaultMessage: "Enter Postal Code",
    },
    setAsDefaultAddress: {
      id: "account.setAsDefaultAddress",
      defaultMessage: "Set as default address",
    },
    addAddress: {
      id: "account.addAddress",
      defaultMessage: "Add Address",
    },
    edit: {
      id: "account.edit",
      defaultMessage: "Edit",
    },
    delete: {
      id: "account.delete",
      defaultMessage: "Delete",
    },
    wantDelete: {
      id: "account.wantDelete",
      defaultMessage: "Do you want to delete",
    },
    yes: {
      id: "account.yes",
      defaultMessage: "Yes",
    },
    no: {
      id: "account.no",
      defaultMessage: "No",
    },
    cannotDelete: {
      id: "account.cannotDelete",
      defaultMessage:
        "You cannot delete your default address. Please select a different address as your default address before deleting this one.",
    },
    editAddress: {
      id: "account.editAddress",
      defaultMessage: "Edit Address",
    },
    home: {
      id: "account.home",
      defaultMessage: "Home",
    },
    work: {
      id: "account.work",
      defaultMessage: "Work",
    },
    other: {
      id: "account.other",
      defaultMessage: "Other",
    },

    nameLabel: {
      id: "account.nameLabel",
      defaultMessage: "Name",
    },
    ProfilePhotoLabel: {
      id: "account.ProfilePhotoLabel",
      defaultMessage: "Prpfile Photo",
    },
    namePlaceholder: {
      id: "account.namePlaceholder",
      defaultMessage: "Enter Client Name",
    },
    emailLabel: {
      id: "account.emailLabel",
      defaultMessage: "Email",
    },
    companyNameLabel: {
      id: "account.companyNameLabel",
      defaultMessage: "Company Name",
    },
    companyNamePlaceholder: {
      id: "account.companyNamePlaceholder",
      defaultMessage: "Enter Company Name",
    },
    dateLabel: {
      id: "account.dateLabel",
      defaultMessage: "Birthdate",
    },
    datePlaceholder: {
      id: "account.datePlaceholder",
      defaultMessage: "Select Birthdate",
    },
    genderLabel: {
      id: "account.genderLabel",
      defaultMessage: "Gender",
    },
    genderPlaceholder: {
      id: "account.genderPlaceholder",
      defaultMessage: "Select Gender",
    },
    save: {
      id: "account.save",
      defaultMessage: "Save",
    },
    female: {
      id: "account.female",
      defaultMessage: "Female",
    },
    male: {
      id: "account.male",
      defaultMessage: "Male",
    },
    changePassword: {
      id: "account.changePassword",
      defaultMessage: "Change Password",
    },
    currentPassowrdLabel: {
      id: "account.currentPassowrdLabel",
      defaultMessage: "Current Passowrd",
    },
    currentPassowrdPlaceholder: {
      id: "account.currentPassowrdPlaceholder",
      defaultMessage: "Enter Current Password",
    },
    newPasswordLabel: {
      id: "account.newPasswordLabel",
      defaultMessage: "New Password",
    },
    newPasswordLPlaceholder: {
      id: "account.newPasswordLPlaceholder",
      defaultMessage: "Enter New Passoword",
    },
    reEnterNewPasswordLabel: {
      id: "account.reEnterNewPasswordLabel",
      defaultMessage: "Re-enter New Password",
    },
    reEnterNewPasswordPlaceholder: {
      id: "account.reEnterNewPasswordPlaceholder",
      defaultMessage: "Re-Enter New Password",
    },
    passwordValidation: {
      id: "auth.passwordValidation",
      defaultMessage:
        "Use 8 or more characters with a mix of letters, numbers & symbols",
    },

    preferences: {
      id: "account.preferences",
      defaultMessage: "Preferences",
    },
    defaultLanguageLabel: {
      id: "account.defaultLanguageLabel",
      defaultMessage: "Language",
    },
    receiveNotification: {
      id: "account.receiveNotification",
      defaultMessage: "Receive Notification from",
    },
    buyAgain: {
      id: "account.buyAgain",
      defaultMessage: "Buy Again",
    },
    emptyProduct: {
      id: "account.emptyProduct",
      defaultMessage: "You don't have any product",
    },
    continueShopping: {
      id: "account.continueShopping",
      defaultMessage: "Continue shopping",
    },
    similarProduct: {
      id: "account.similarProduct",
      defaultMessage: "Similar Product",
    },
    topSelling: {
      id: "account.topSelling",
      defaultMessage: "Top Selling",
    },
    view: {
      id: "account.view",
      defaultMessage: "View",
    },
    phoneInvalid: {
      id: "global.phoneInvalid",
    },

    noCreditsTitle: {
      id: "account.noCreditsTitle",
      defaultMessage: "No credits available",
    },
    noCreditsDescription: {
      id: "account.noCreditsDescription",
      defaultMessage:
        "Credits and cashback you accumulate from any marketplace on Matjr will display here",
    },
    walletHistory: {
      id: "account.walletHistory",
      defaultMessage: "Wallet History",
    },
    transactionType: {
      id: "account.transactionType",
      defaultMessage: "Transaction Type",
    },
    amount: {
      id: "account.amount",
      defaultMessage: "Amount",
    },
    status: {
      id: "account.status",
      defaultMessage: "Status",
    },
    details: {
      id: "account.details",
      defaultMessage: "Details",
    },
    transactionDate: {
      id: "account.transactionDate",
      defaultMessage: "Date",
    },
    points: {
      id: "account.points",
      defaultMessage: "Points",
    },
    loyaltyPoints: {
      id: "account.loyaltyPoints",
      defaultMessage: "Loyalty Points",
    },
    referralProgram: {
      id: "account.referralProgram",
      defaultMessage: "Referral Program",
    },
    affiliateProgram: {
      id: "account.affiliateProgram",
      defaultMessage: "Affiliate Program",
    },
    myPoints: {
      id: "account.myPoints",
      defaultMessage: "My Points",
    },
    point: {
      id: "account.point",
      defaultMessage: "point",
    },
    redeem: {
      id: "account.redeem",
      defaultMessage: "Redeem",
    },
    earnedPoint: {
      id: "account.earnedPoint",
      defaultMessage: "Earned point",
    },
    expiredSoon: {
      id: "account.expiredSoon",
      defaultMessage: "Expired soon",
    },
    expiredPoints: {
      id: "account.expiredPoints",
      defaultMessage: "Expired Points",
    },
    pointsHistory: {
      id: "account.pointsHistory",
      defaultMessage: "Points History",
    },
    expireOn: {
      id: "account.expireOn",
      defaultMessage: "Expired on",
    },
    redeemPoints: {
      id: "account.redeemPoints",
      defaultMessage: "Redeem Points to My Wallet",
    },
    pointsLabel: {
      id: "account.pointsLabel",
      defaultMessage: "Points to redeem",
    },
    type: {
      id: "account.type",
      defaultMessage: "Type",
    },
    useAddress: {
      id: "account.useAddress",
      defaultMessage: "Use Address",
    },
    deliveryCode: {
      id: "account.deliveryCode",
      defaultMessage: "Delivery Code",
    },
    note: {
      id: "checkout.note",
      defaultMessage: "Note",
    },
    minutes: {
      id: "branch.minutes",
      defaultMessage: "Minutes",
    },
    deliveryEta: {
      id: "account.deliveryEta",
      defaultMessage: "Time Of Arrival",
    },
    deliveryEtaDesc: {
      id: "account.deliveryEtaDesc",
      defaultMessage: "Estimated time of arrival",
    },
    copiedSuccess: {
      id: "account.copiedSuccess",
      defaultMessage: "Copied Successfully",
    },
    uploadPhoto: {
      id: "account.uploadPhoto",
      defaultMessage: "Upload Photo",
    },
    currentCommission: {
      id: "account.currentCommission",
      defaultMessage: "Current commission rate",
    },
    affiliateRule: {
      id: "account.affiliateRule",
      defaultMessage: "Affiliate Rule",
    },
    referralCode: {
      id: "account.referralCode",
      defaultMessage: "Referral Code",
    },
    totalPoints: {
      id: "account.totalPoints",
      defaultMessage: "Total Point",
    },
    firstPurchase: {
      id: "account.firstPurchase",
      defaultMessage: "First Purchase",
    },
    referralDesc: {
      id: "account.referralDesc",
      defaultMessage:
        "Unique Referral code can be copied & shared with others to refer them to the website.",
    },
    commissionHistory: {
      id: "account.commissionHistory",
      defaultMessage: "Commission history",
    },
    detailsCommissionEarned: {
      id: "account.detailsCommissionEarned",
      defaultMessage: "details for each commission earned",
    },
    earned: {
      id: "account.earned",
      defaultMessage: "Earned",
    },
    purchaseAmount: {
      id: "account.purchaseAmount",
      defineMessages: "Purchase Amount",
    },
    commissionRate: {
      id: "account.commissionRate",
      defaultMessage: "Commission Rate",
    },
  })
);
