import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchIcon } from "assets/icons";
import Messages from "components/Search/i18nStrings";
import client from "utils/plugins/ApiClient";
const SearchForm = () => {
  const history = useHistory();
  const [showResults, setShowResults] = useState(false);
  const resultsRef = useRef(null);
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );
  const formik = useFormik({
    initialValues: {
      text: "",
    },
    onSubmit: (values) => {
      const trimmedText = values.text.trim();
      if (trimmedText) {
        history.push(`/search-results?query=${trimmedText}`);
      } else {
        toast.warn(Messages.getFormatMessage("fieldAdd"));
      }
    },
  });

  const { data } = useQuery(
    ["searchData", formik.values.text],
    async () => {
      const response = await client.get(`/products`, {
        params: {
          service_id: selectedService,
          text: formik.values.text,
        },
      });
      return response.data.response.data;
    },
    {
      enabled: !!formik.values.text,
    }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleKeyUp = (event) => {
    if (formik.values.text.trim() !== "") {
      setShowResults(true);
    }
  };

  return (
    <div className={`relative w-full my-2 lg:my-0 lg:w-5/12`}>
      <form onSubmit={formik.handleSubmit} className={`group flex relative `}>
        <input
          type="text"
          id="text"
          name="text"
          placeholder={Messages.getFormatMessage("search")}
          className=" border text-sm pe-10 border-gray-100 text-gray-700 placeholder:text-gray-700 focus:ring-transparent w-full rounded-lg appearance-none disabled:text-gray-700/50 focus-within:border-primary-400"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyUp={handleKeyUp} // Show results when typing continues
          value={formik.values.text}
          onFocus={() => setShowResults(true)}
          autoComplete="off" // Disable autocomplete
        />
        <button
          type="submit"
          className="rounded-e-md absolute top-0 end-0 p-2 pt-3 bottom-0 bg-primary-500  flex items-center group-focus-within:bg-primary-400 justify-center px-3 h-full border-none text-white rounded-r rtl:rounded-l-lg rtl:rounded-r-none border border-gray-250 "
        >
          <SearchIcon size={20} className="text-white " />
        </button>
        {/* <button
          type="submit"
          className="flex items-center group-focus-within:bg-primary-400 justify-center px-3 h-full bg-gray-50 border-none absolute top-0 end-0 text-white rounded-r rtl:rounded-l-lg rtl:rounded-r-none border border-gray-250 "
        >
         
          <span className="sr-only">{Messages.getFormatMessage("search")}</span>
        </button> */}
      </form>
      {data && data.length > 0 && showResults && (
        <div
          ref={resultsRef}
          className="w-full p-5 absolute z-50 bg-white rounded-b "
        >
          {/* Render the search results */}
          {Array.isArray(data) && data.length > 0 ? (
            <ul className="flex flex-col gap-2">
              {data.map((result) => (
                <li key={result.id}>
                  <a href={`/products/${result.id}`}>{result.name}</a>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SearchForm;
