/*eslint-disable*/
import React, { useState, useEffect } from "react";
// components
import SignUp from "components/Authentication/SignUp";
import SignIn from "components/Authentication/SignIn";
import VerifyOtp from "components/Authentication/VerifyOtp";
import { get as _get } from "lodash";
import qs from "query-string";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import LocationModal from "components/Maps/LocationModal";
export default function AuthContainer({
  isShowModalSignIn,
  handleTriggerModalSignIn,
}) {
  const history = useHistory();
  const { open } = useSelector((state) => state.Auth.login);
  const [location, setLocation] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState("sms");
  const [selectedMobile, setSelectedMobile] = useState("");
  const [isShowModalSignUp, setIsShowModalSignUp] = useState(false);
  const [isShowModalVerifyOtp, setIsShowModalVerifyOtp] = useState(false);
  const [isShowModalPickAddress, setIsShowModalPickAddress] = useState(false);
  const [isShowModalLocation, setIsShowModalLocation] = useState(false);
  const handleTriggerModalSignUp = () =>
    setIsShowModalSignUp(!isShowModalSignUp);
  const handleTriggerModalVerifyOtp = () =>
    setIsShowModalVerifyOtp(!isShowModalVerifyOtp);
  const handleTriggerModalPickAddress = () =>
    setIsShowModalPickAddress(!isShowModalPickAddress);
  const handleTriggerModalLocation = () =>
    setIsShowModalLocation(!isShowModalLocation);
  useEffect(() => {
    localStorage.getItem("@matjr__statusModal") === "register" &&
      handleTriggerModalSignUp();

    localStorage.getItem("@matjr__statusModal") === "verifyOtp" &&
      handleTriggerModalVerifyOtp();

    localStorage.getItem("@matjr__statusModal") === "signIn" &&
      handleTriggerModalSignIn();
    // localStorage.getItem("@matjr__statusModal") === "pickAddress" &&
    //   handleTriggerModalPickAddress();
    localStorage.getItem("@matjr__statusModal") === "pickAddress" &&
      handleTriggerModalLocation();
  }, []);

  useEffect(() => {
    open && handleTriggerModalSignIn();
  }, [open]);
  return (
    <>
      {isShowModalSignUp && (
        <SignUp handleCloseModal={handleTriggerModalSignUp} />
      )}
      {isShowModalSignIn && (
        <SignIn
          handleCloseModal={handleTriggerModalSignIn}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          setSelectedMobile={setSelectedMobile}
          handleTriggerModalVerifyOtp={handleTriggerModalVerifyOtp}
        />
      )}

      {isShowModalVerifyOtp && (
        <VerifyOtp
          handleCloseModal={handleTriggerModalVerifyOtp}
          mobile={selectedMobile}
          channel={selectedChannel}
          handleTriggerModalSignUp={handleTriggerModalSignUp}
        />
      )}
      {isShowModalLocation && (
        <LocationModal
          location={location}
          setLocation={setLocation}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          handleCloseModal={handleTriggerModalLocation}
        />
      )}
    </>
  );
}
