import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppLocale from "config/translation";
import updateLocalStorage from "utils/helpers/updateLocalStorage";
import { actions as authActions } from "redux/auth/actions";
import { actions as userprofileActions } from "redux/userprofile/actions";
import { actions as orderActions } from "redux/order/actions";
import languageSwitcherActions from "redux/languageSwitcher/actions";
import { dateDiffInDays } from "utils/helpers/dateDiffInDays";
import { actions as serviceBranchesActions } from "redux/branches/actions";
import { actions as serviceProductsActions } from "redux/products/actions";
import { actions as selectedServiceActions } from "redux/selectedService/actions";
import { actions as servicesActions } from "redux/services/actions";
import { actions as settingsActions } from "redux/settings/actions";
import { actions as remoteConfigActions } from "redux/remoteConfig/actions";
import client from "utils/plugins/ApiClient";
import { useQueryClient } from "react-query";
import { get as _get } from "lodash";

function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const currentAppLocale = AppLocale[locale];
  const dispatch = useDispatch();
  const User = useSelector((state) => state.User);
  const isLoggedIn = _get(User, "token", false);
  const { config: settings, isLoading: isSettingsLoading } = useSelector(
    (state) => state.Settings.settings
  );
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);

  const { cart, isLoading: isFetching } = useSelector(
    (state) => state.Order.orders
  );

  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  );

  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  useEffect(() => {
    if (!isRemoteConfigLoading && !remoteConfig) {
      dispatch(remoteConfigActions.remoteConfigRequest());
    }
  }, [isRemoteConfigLoading, remoteConfig]);
  useEffect(() => {
    if (!isRemoteConfigLoading && isLoggedIn && !isProfileLoading && !profile) {
      dispatch(userprofileActions.userprofileRequest());
    }
  }, [isLoggedIn, isProfileLoading, profile, isRemoteConfigLoading]);

  const queryClient = useQueryClient();
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );
  const [useDefaultLocation, setUseDefaultLocation] = useState(
    localStorage.getItem("use_default_location")
  );
  const [districtId, setDistrictId] = useState(
    localStorage.getItem("district_id")
  );
  useEffect(() => {
    if (!isRemoteConfigLoading) {
      updateLocalStorage(
        "use_default_location",
        remoteConfig?.force_select_default_locations ? "true" : "false"
      );
      if (!selectedService && remoteConfig?.default_service_id) {
        updateLocalStorage("selectedService", remoteConfig?.default_service_id);
      }
      if (remoteConfig?.force_select_default_locations) {
        if (isLoggedIn && !isProfileLoading && profile) {
          if (profile?.address) {
            updateLocalStorage("district_id", profile?.address.district_id);
            const selectedLocation = {
              lat: profile?.address.location.latitude,
              lng: profile?.address.location.longitude,
            };
            updateLocalStorage("location", JSON.stringify(selectedLocation));
            const selectedAddress = {
              name: profile?.address.name,
              region: profile?.address.region,
              country: profile?.address.country_id.name,
              postalCode: profile?.address.postal_code,
              city: profile?.address.city_id.name,
              streetAddress: profile?.address.address_details,
            };
            updateLocalStorage(
              "selectedAddress",
              JSON.stringify(selectedAddress)
            );
            updateLocalStorage("address_id", profile?.address.id);
          } else {
            localStorage.removeItem("district_id");
          }
        }
        if (!isLoggedIn) {
          updateLocalStorage(
            "district_id",
            remoteConfig?.default_location.district_id
          );
          const selectedLocation = {
            lat: remoteConfig?.default_location.latitude,
            lng: remoteConfig?.default_location.longitude,
          };
          updateLocalStorage("location", JSON.stringify(selectedLocation));
        }
      }
    }
  }, [
    remoteConfig,
    isRemoteConfigLoading,
    profile,
    isProfileLoading,
    isLoggedIn,
    selectedService,
  ]);

  useEffect(() => {
    const handleStorageUpdate = () => {
      const newSelectedService = localStorage.getItem("selectedService");
      setSelectedService(newSelectedService);
      const newUseDefaultLocation = localStorage.getItem(
        "use_default_location"
      );
      setUseDefaultLocation(newUseDefaultLocation);
      const newDistrictId = localStorage.getItem("district_id");
      setDistrictId(newDistrictId);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, []);

  useEffect(() => {
    if (locale === "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
      client.defaults.headers.Language = "ar";
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
      client.defaults.headers.Language = "en";
    }
    queryClient.resetQueries({ exact: true });
    dispatch(remoteConfigActions.remoteConfigRequest());
  }, [locale]);

  useEffect(() => {
    if (districtId) {
      client.defaults.headers["district-id"] = districtId;
      dispatch(servicesActions.servicesRequest());
    }
  }, [districtId, locale]);
  useEffect(() => {
    if (!isRemoteConfigLoading && selectedService && districtId) {
      dispatch(selectedServiceActions.selectedServiceRequest(selectedService));
      dispatch(
        serviceProductsActions.productsRequest({ service_id: selectedService })
      );
      dispatch(
        serviceBranchesActions.branchesRequest({ service_id: selectedService })
      );
    }
  }, [isRemoteConfigLoading, locale, selectedService, districtId]);

  useEffect(() => {
    if (!isRemoteConfigLoading && selectedService && isLoggedIn) {
      dispatch(orderActions.ordersRequest({ service_id: selectedService }));
    }
  }, [isRemoteConfigLoading, selectedService, isLoggedIn, locale]);
  useEffect(() => {
    if (localStorage.getItem("@matjr-current_currency")) {
      client.defaults.headers["currency"] = localStorage.getItem(
        "@matjr-current_currency"
      );
    } else {
      updateLocalStorage("@matjr-current_currency", "USD");
      client.defaults.headers["currency"] = "USD";
    }
  }, []);

  useEffect(() => {
    if (!isProfileLoading && profile && isLoggedIn) {
      profile?.default_locale === "en"
        ? dispatch(languageSwitcherActions.changeLanguage("english"))
        : dispatch(languageSwitcherActions.changeLanguage("arabic"));
    }
  }, [isLoggedIn, profile?.default_locale]);

  useEffect(() => {
    if (!isSettingsLoading && !settings) {
      dispatch(settingsActions.settingsRequest());
    }
  }, [isSettingsLoading, settings]);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <>{children}</>
    </IntlProvider>
  );
}

export default AppProvider;
