import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/remoteConfig/actions";
import updateLocalStorage from "utils/helpers/updateLocalStorage";
import IntlMessages from "components/Utils/intlMessages";
const ServicesSplash = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { services, isLoading } = useSelector(
    (state) => state.Services.districtServices
  );
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );

  useEffect(() => {
    if (selectedService && !isLoading && services?.length > 0) {
      const selectedServiceItem = services?.find(
        (service) => service.id == selectedService
      );
      if (selectedServiceItem) {
        updateLocalStorage("selectedServiceName", selectedServiceItem.name);
      }
    }
    const handleStorageUpdate = () => {
      const newSelectedService = localStorage.getItem("selectedService");
      setSelectedService(newSelectedService);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, [selectedService, isLoading, services]);

  const handleServiceClick = (serviceId) => {
    updateLocalStorage("selectedService", serviceId);
    const selectedServiceItem = services.find(
      (service) => service.id === serviceId
    );
    if (selectedServiceItem) {
      updateLocalStorage("selectedServiceName", selectedServiceItem.name);
    }
    dispatch(actions.serviceSwitcher(serviceId));
    history.push("/home");
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">
          {" "}
          <IntlMessages id="global.selectService" />
        </h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-wrap items-center gap-2">
        {!isLoading &&
          services?.map((service) => (
            <div
              key={service.id}
              className={`cursor-pointer `}
              onClick={() => handleServiceClick(service.id)}
            >
              <img src={service.icon} alt={service.name} className="h-28" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServicesSplash;
