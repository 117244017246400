import { combineReducers } from "redux";
import App from "redux/app/reducer";
import Auth from "redux/auth/reducer";
import User from "redux/User/reducer";
import Order from "redux/order/reducer";
import Settings from "redux/settings/reducer";
import RemoteConfig from "redux/remoteConfig/reducer";
import LanguageSwitcher from "redux/languageSwitcher/reducer";
import Userprofile from "redux/userprofile/reducer";
import SelectedService from "redux/selectedService/reducer";
import Branches from "redux/branches/reducer";
import Services from "redux/services/reducer";
import Products from "redux/products/reducer";
const allReducers = combineReducers({
  App,
  Auth,
  User,
  Order,
  Settings,
  RemoteConfig,
  LanguageSwitcher,
  Userprofile,
  SelectedService,
  Branches,
  Services,
  Products,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default rootReducer;
