import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { useHistory } from "react-router-dom";
import Messages from "components/Views/ProductsFiltration/i18nStrings";
import { ListIcon } from "assets/icons";
import Subcategory from "components/Navbars/Subcategory";
import NoData from "components/Cards/NoData";
import Spinner from "components/Spinner";
import listenForOutsideClick from "utils/helpers/listen-for-outside-clicks";

const CategoriesDropdown = ({ categories, isLoading }) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [isMobileSplashOpen, setIsMobileSplashOpen] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const history = useHistory();

  const handleCategoryHover = (category) => {
    setHoveredCategory(category);
  };

  const handleCategoryLeave = () => {
    setHoveredCategory(null);
  };

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleCategoryClick = (categoryId) => {
    history.push(`/categories/${categoryId}`);
    setDropdownPopoverShow(false);
    setIsMobileSplashOpen(false);
  };

  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileSplashOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <button
        ref={btnDropdownRef}
        className="text-base whitespace-nowrap flex-shrink-0 font-inter-medium text-gray-500 flex items-center gap-2"
        onClick={(e) => {
          e.preventDefault();
          if (window.innerWidth < 768) {
            setIsMobileSplashOpen(true);
          } else {
            dropdownPopoverShow
              ? closeDropdownPopover()
              : openDropdownPopover();
          }
        }}
      >
        <ListIcon /> {Messages.getFormatMessage("allCategories")}
      </button>

      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownPopoverShow ? "flex" : "hidden"
        } justify-center gap-8 !mt-3 bg-white text-base w-full z-30 absolute top-5 left-0 list-none text-start rounded shadow-xl min-w-[10rem]`}
      >
        <div className="absolute left-0 w-full bg-white shadow-lg p-5">
          <div className="flex">
            {isLoading ? (
              <div className="w-full items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="w-1/4 max-h-[70vh] overflow-y-scroll p-4">
                  {categories?.map((category) => (
                    <div
                      key={category.id}
                      onMouseEnter={() => handleCategoryHover(category)}
                      className="relative"
                    >
                      <button
                        className={`w-full flex items-center gap-2 py-2 px-4 text-left ${
                          hoveredCategory && hoveredCategory.id === category.id
                            ? "bg-gray-200"
                            : ""
                        }`}
                        onClick={() => handleCategoryClick(category.id)}
                      >
                        <div className="border-e border-gray-300 pe-5">
                          <img
                            src={category.icon}
                            className="w-10 h-10 object-contain flex-shrink-0"
                          />
                        </div>
                        {category.name?.substring(0, 20)}
                        {category.name?.length > 20 && ".."}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="w-3/4 px-12 py-2">
                  <div className="flex flex-wrap gap-4 md:gap-8">
                    {hoveredCategory && hoveredCategory.children.length > 0 ? (
                      hoveredCategory?.children.map((subcategory, index) => (
                        <Subcategory key={index} category={subcategory} />
                      ))
                    ) : (
                      <NoData
                        subtitle={Messages.getFormatMessage("noSubcategories")}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isMobileSplashOpen && (
        <div className="fixed inset-0 bg-white z-40 overflow-auto">
          <div className="p-5">
            <button
              className="text-base font-inter-medium text-gray-500 mb-4"
              onClick={() => setIsMobileSplashOpen(false)}
            >
              Close
            </button>
            <div className="flex flex-col">
              {isLoading ? (
                <div className="w-full items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className="max-h-[70vh] overflow-y-scroll p-4">
                    {categories?.map((category) => (
                      <div key={category.id} className="relative">
                        <button
                          className={`w-full flex items-center gap-2 py-2 px-4 text-left ${
                            hoveredCategory &&
                            hoveredCategory.id === category.id
                              ? "bg-gray-200"
                              : ""
                          }`}
                          onClick={() => handleCategoryClick(category.id)}
                        >
                          <div className="border-e border-gray-300 pe-5">
                            <img
                              src={category.icon}
                              className="w-10 h-10 object-contain flex-shrink-0"
                            />
                          </div>
                          {category.name?.substring(0, 20)}
                          {category.name?.length > 20 && ".."}
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="px-12 py-2">
                    <div className="flex flex-wrap gap-4 md:gap-8">
                      {hoveredCategory &&
                      hoveredCategory.children.length > 0 ? (
                        hoveredCategory?.children.map((subcategory, index) => (
                          <Subcategory key={index} category={subcategory} />
                        ))
                      ) : (
                        <NoData
                          subtitle={Messages.getFormatMessage(
                            "noSubcategories"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoriesDropdown;
