import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    methodMsg: {
      id: "auth.methodMsg",
      defaultMessage: "Select preferred method to verify phone number",
    },
    or: {
      id: "auth.or",
      defaultMessage: "OR",
    },
    viaSms: {
      id: "auth.viaSms",
      defaultMessage: "Continue Via Sms",
    },
    viaWhatsapp: {
      id: "auth.viaWhatsapp",
      defaultMessage: "Continue Via Whatsapp",
    },
    mobileLabel: {
      id: "auth.mobileLabel",
      defaultMessage: "Phone number",
    },
    logIn: {
      id: "auth.logIn",
      defaultMessage: "Login",
    },
    otpSent: {
      id: "auth.otpSent",
      defaultMessage: "Otp sent to your mobile",
    },
    verifyOtp: {
      id: "auth.verifyOtp",
      defaultMessage: "Verify Your Otp",
    },
    verifyOtpDescription: {
      id: "auth.verifyOtpDescription",
      defaultMessage:
        "Enter the 4 digit code sent to the registered mobile number.",
    },
    didNotReceive: {
      id: "auth.didNotReceive",
      defaultMessage: "Did not receive a code ?",
    },
    resend: {
      id: "auth.resend",
      defaultMessage: "Resend",
    },
    wait: {
      id: "auth.wait",
      defaultMessage: "Wait",
    },
    resendMsg: {
      id: "auth.resendMsg",
      defaultMessage: "seconds before resending!",
    },
    verify: {
      id: "auth.verify",
      defaultMessage: "Verify",
    },
    verifyWarning: {
      id: "auth.verifyWarning",
      defaultMessage: "Don't share the verification code with anyone !",
    },
    signUp: {
      id: "auth.signUp",
      defaultMessage: "Sign up",
    },
    fullNameLabel: {
      id: "auth.fullNameLabel",
      defaultMessage: "Full Name",
    },
    fullNamePlaceholder: {
      id: "auth.fullNamePlaceholder",
      defaultMessage: "Enter your full name",
    },

    emailAddressLabel: {
      id: "auth.emailAddressLabel",
      defaultMessage: "Email",
    },
    emailAddressPlaceholder: {
      id: "auth.emailAddressPlaceholder",
      defaultMessage: "Enter your email",
    },

    referralCodeLabel: {
      id: "auth.referralCodeLabel",
      defaultMessage: "Referral Code",
    },
    referralCodePlaceholder: {
      id: "auth.referralCodePlaceholder",
      defaultMessage: "Enter Valid Referral Code",
    },

    iAgree: {
      id: "auth.iAgree",
      defaultMessage: "I agree to the",
    },
    termsConditions: {
      id: "auth.termsConditions",
      defaultMessage: "Terms & conditions",
    },
    createAccount: {
      id: "auth.createAccount",
      defaultMessage: "Create account",
    },

    backToLogIn: {
      id: "auth.backToLogIn",
      defaultMessage: "Back to log in",
    },
    otpVerified: {
      id: "auth.otpVerified",
      defaultMessage: "Otp verified successfully",
    },
    otpVerifiedRegister: {
      id: "auth.otpVerifiedRegister",
      defaultMessage: "Otp verified, Complete your registration",
    },
    accountCreated: {
      id: "auth.accountCreated",
      defaultMessage: "Account Created Successfully",
    },
    signUpButton: {
      id: "auth.signUpButton",
      defaultMessage: "Sign up",
    },
    // old
    emailSentTitle: {
      id: "auth.emailSentTitle",
      defaultMessage: "Email has been sent",
    },
    emailSentDescription: {
      id: "auth.emailSentDescription",
      defaultMessage:
        "Please check your email inbox for a password recovery link. Don't forget to check your spam folder.",
    },
    donotFindEmail: {
      id: "auth.donotFindEmail",
      defaultMessage: "Didn't find the email?",
    },
    resendNow: {
      id: "auth.resendNow",
      defaultMessage: "Resend Now",
    },
    backTo: {
      id: "auth.backTo",
      defaultMessage: "Back to",
    },
    logIn: {
      id: "auth.logIn",
      defaultMessage: "Login",
    },

    forgotPasswordTitle: {
      id: "auth.forgotPasswordTitle",
      defaultMessage: "Forgot password",
    },
    forgotPasswordDescription: {
      id: "auth.forgotPasswordDescription",
      defaultMessage:
        "Enter the email address you use on All Academy. We'll send you a link to reset your password.",
    },
    emailAddressLabel: {
      id: "auth.emailAddressLabel",
      defaultMessage: "Email Address",
    },
    emailAddressPlaceholder: {
      id: "auth.emailAddressPlaceholder",
      defaultMessage: "Enter your email address",
    },
    resetPasswordButton: {
      id: "auth.resetPasswordButton",
      defaultMessage: "Reset My Password",
    },

    resetPasswordTitle: {
      id: "auth.resetPasswordTitle",
      defaultMessage: "Reset Password",
    },
    newPasswordLabel: {
      id: "auth.newPasswordLabel",
      defaultMessage: "Enter New Password",
    },
    newPasswordPlaceholder: {
      id: "auth.newPasswordPlaceholder",
      defaultMessage: "New Password",
    },
    confirmPasswordLabel: {
      id: "auth.confirmPasswordLabel",
      defaultMessage: "Confirm your New Password",
    },
    confirmPasswordPlaceholder: {
      id: "auth.confirmPasswordPlaceholder",
      defaultMessage: "Confirm Password",
    },

    signInTitle: {
      id: "auth.signInTitle",
      defaultMessage: "Welcome Back",
    },
    passwordLabel: {
      id: "auth.passwordLabel",
      defaultMessage: "Password",
    },
    passwordPlaceholder: {
      id: "auth.passwordPlaceholder",
      defaultMessage: "Create your password",
    },
    enterPasswordPlaceholder: {
      id: "auth.enterPasswordPlaceholder",
      defaultMessage: "Enter your password",
    },

    signUpTitle: {
      id: "auth.signUpTitle",
      defaultMessage: "Sign up and start learning",
    },
    fullNameLabel: {
      id: "auth.fullNameLabel",
      defaultMessage: "Full Name",
    },
    fullNamePlaceholder: {
      id: "auth.fullNamePlaceholder",
      defaultMessage: "Enter your full name",
    },
    submit: {
      id: "auth.submit",
      defaultMessage: "Submit",
    },
    bySigningUp: {
      id: "auth.bySigningUp",
      defaultMessage: "By signing up, you agree to the",
    },
    bySigningUpInstructor: {
      id: "auth.bySigningUpInstructor",
      defaultMessage: "By Joining up, you agree to the",
    },
    terms: {
      id: "auth.terms",
      defaultMessage: "Terms Policy",
    },
    privacy: {
      id: "auth.privacy",
      defaultMessage: "Privacy &",
    },
    alreadyHaveAcc: {
      id: "auth.alreadyHaveAcc",
      defaultMessage: "Already have an account?",
    },

    successResetTitle: {
      id: "auth.successResetTitle",
      defaultMessage: "Successful password reset!",
    },
    successResetDescription: {
      id: "auth.successResetDescription",
      defaultMessage:
        "Your password has been successfully reset. Now you can login with the new password.",
    },

    successSignUpTitle: {
      id: "auth.successSignUpTitle",
      defaultMessage: "Sign Up Success!",
    },
    successSignUpDescription: {
      id: "auth.successSignUpDescription",
      defaultMessage: "You have signed up successfully",
    },

    verifyAccountTitle: {
      id: "auth.verifyAccountTitle",
      defaultMessage: "Verify your email",
    },
    sentConfirmEmail: {
      id: "auth.sentConfirmEmail",
      defaultMessage: "We have sent a confirmation email link to ",
    },
    checkEmailValidate: {
      id: "auth.checkEmailValidate",
      defaultMessage: "please check your email to validate your account. ",
    },
    clickHere: {
      id: "auth.clickHere",
      defaultMessage: "Click here",
    },
    didnotReceiveEmail: {
      id: "auth.didnotReceiveEmail",
      defaultMessage: "if you didn't receive an email",
    },

    instructorConfirmTitle: {
      id: "auth.instructorConfirmTitle",
      defaultMessage: "Confirm Privacy & Terms Policy",
    },
    instructorConfirmDescription: {
      id: "auth.instructorConfirmDescription",
      defaultMessage:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. It is also used",
    },
    accept: {
      id: "auth.accept",
      defaultMessage: "Accept",
    },
    decline: {
      id: "auth.decline",
      defaultMessage: "Decline",
    },
    instructorSuccessTitle: {
      id: "auth.instructorSuccessTitle",
      defaultMessage: "Welcome,on staff",
    },
    instructorSuccessDescription: {
      id: "auth.instructorSuccessDescription",
      defaultMessage: "Welcome, Now you  are one of All academy staff",
    },

    emailVerified: {
      id: "notification.emailVerified",
      defaultMessage: "Email verified Successfully!",
    },
    resetPasswordSent: {
      id: "notification.resetPasswordSent",
      defaultMessage: "Reset password link sent to your email",
    },
    verificationReSent: {
      id: "notification.verificationReSent",
      defaultMessage: "verification link re-sent to your email",
    },
    passwordUpdated: {
      id: "notification.passwordUpdated",
      defaultMessage: "Password Updated successfully!",
    },
    loggedSuccessfully: {
      id: "notification.loggedSuccessfully",
      defaultMessage: "Logged in Successfully",
    },
    accountCreated: {
      id: "notification.accountCreated",
      defaultMessage: "Account Created Successfully",
    },

    invalidPassword: {
      id: "global.invalidPassword",
    },

    joinAsInstructor: {
      id: "auth.joinAsInstructor",
      defaultMessage: "Join as Instructor",
    },
    instructorName: {
      id: "auth.instructorName",
      defaultMessage: "Instructor Name",
    },
    headline: {
      id: "auth.headline",
      defaultMessage: "Headline",
    },
    biography: {
      id: "auth.biography",
      defaultMessage: "Biography",
    },
    website: {
      id: "auth.website",
      defaultMessage: "Website",
    },
    url: {
      id: "auth.url",
      defaultMessage: "URL",
    },
    twitter: {
      id: "auth.twitter",
      defaultMessage: "Twitter",
    },
    linkedIn: {
      id: "auth.linkedIn",
      defaultMessage: "LinkedIn",
    },
    youtube: {
      id: "auth.youtube",
      defaultMessage: "Youtube",
    },
    facebook: {
      id: "auth.facebook",
      defaultMessage: "Facebook",
    },
    complete: {
      id: "auth.complete",
      defaultMessage: "Complete",
    },
    joinInstructorSuccessfully: {
      id: "auth.joinInstructorSuccessfully",
      defaultMessage: "Join as Instructor successfully",
    },
    username: {
      id: "auth.username",
      defaultMessage: "Username",
    },

    termsCndConditions: {
      id: "footer.termsAndConditions",
      defaultMessage: "Terms & Conditions",
    },
    iPPolicy: {
      id: "footer.iPPolicy",
      defaultMessage: "IP Policy",
    },
  })
);
