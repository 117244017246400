import React, { Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { get as _get } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";
import ScrollToTop from "utils/helpers/ScrollToTop";
import Loader from "components/Utils/loader";
import { publicRoutes, privateRoutes, NotFound } from "./route.constants";
import ErrorBoundary from "./ErrorBoundary";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import { toast } from "react-toastify";

function PublicRoute({
  children,
  layout: Layout,
  isRestricted,
  locationRequired,
  ...rest
}) {
  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);
  const districtId = localStorage.getItem("district_id");
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);

  // Check if location is required and districtId is not set
  if (isLoggedIn && locationRequired && !districtId) {
    return (
      <Route
        {...rest}
        render={() => {
          locale === "ar"
            ? toast.error("يجب أن تختار عنوان")
            : toast.error("Please choose an address");

          return <Redirect to="/account/my-address" />;
        }}
      />
    );
  }

  if (!isLoggedIn && locationRequired && !districtId) {
    return (
      <Route
        {...rest}
        render={() => {
          locale === "ar"
            ? toast.error("يجب أن تختار عنوان")
            : toast.error("Please choose an address");
          return <Redirect to="/" />;
        }}
      />
    );
  }

  if (!isLoggedIn && !isRestricted) {
    return <Route {...rest} render={() => <Layout>{children}</Layout>} />;
  }

  if (!isLoggedIn && isRestricted) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }

  // Default route
  return <Route {...rest} render={() => <Layout>{children}</Layout>} />;
}

function PrivateRoute({
  children,
  layout: Layout,
  isRestricted,
  locationRequired,
  ...rest
}) {
  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);
  const districtId = localStorage.getItem("district_id");
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);

  // Check if location is required and districtId is not set
  if (isLoggedIn && locationRequired && !districtId) {
    return (
      <Route
        {...rest}
        render={() => {
          locale === "ar"
            ? toast.error("يجب أن تختار عنوان")
            : toast.error("Please choose an address");
          return <Redirect to="/account/my-address" />;
        }}
      />
    );
  }

  if (!isLoggedIn && locationRequired && !districtId) {
    return (
      <Route
        {...rest}
        render={() => {
          locale === "ar"
            ? toast.error("يجب أن تختار عنوان")
            : toast.error("Please choose an address");
          return <Redirect to="/" />;
        }}
      />
    );
  }

  if (isLoggedIn && isRestricted) {
    return <Route {...rest} render={() => <Layout>{children}</Layout>} />;
  }

  if (!isLoggedIn && isRestricted) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }

  // Default route
  return <Route {...rest} render={() => <Layout>{children}</Layout>} />;
}

function Routes(props) {
  reactIntl.injectIntl(props.intl);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <ScrollToTop>
            <Switch>
              {publicRoutes.map((route) => (
                <PublicRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  isRestricted={false}
                  layout={route.auth ? AuthLayout : MainLayout}
                  locationRequired={route.locationRequired}
                >
                  <route.component />
                </PublicRoute>
              ))}
              {privateRoutes.map((route) => (
                <PrivateRoute
                  exact={route.exact}
                  key={route.path}
                  path={route.path}
                  layout={MainLayout}
                  isRestricted={true}
                  locationRequired={route.locationRequired}
                >
                  <route.component />
                </PrivateRoute>
              ))}
              <Route>
                <MainLayout>
                  <NotFound />
                </MainLayout>
              </Route>
            </Switch>
          </ScrollToTop>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default injectIntl(Routes);
