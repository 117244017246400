import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";
import { toast } from "react-toastify";

export const getSettings = () => {
  return ApiClient.get("/setting")
    .then((response) => {
      // console.log(response.data.response.data)
      return response.data.response.data;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};
