import { toast } from "react-toastify";
import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";

export const registerUser = (payload) =>
  ApiClient.post("/user/register-by-otp", payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error);
    });

export const verifyEmail = (payload) =>
  ApiClient.post("/user/email/verify", payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error);
    });

export const loginUser = (payload) =>
  ApiClient.post("/user/login-by-otp", payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error);
    });
export const verifyOtp = (payload) =>
  ApiClient.post("/user/verify-login-by-otp", payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error);
    });

export const forgetPassword = (payload) =>
  ApiClient.post("/user/forget-password", payload)
    .then((respose) => {
      toast.success(respose.data.message);
      return respose.data.response.data;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
export const resetPassword = (payload) =>
  ApiClient.post("/user/password/reset", payload)
    .then((respose) => {
      toast.success(respose.data.message);
      return respose.data.response.data;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
