import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";
import { toast } from "react-toastify";
export const createOrder = (payload) =>
  ApiClient.post("/order/add-to-cart", payload)
    .then((response) => {
      toast.success(response.data.message);
      return response.data.response.data;
    })
    .catch((error) => {
      // toast.error(error.response.data.message);
      // toast.error(HandleAxiosError(error));
      // return error;
      // throw HandleAxiosError(error);
      return error;
    });

export const updateOrder = (payload) =>
  ApiClient.post("/order/update-cart", payload)
    .then((response) => {
      toast.success(response.data.message);
      return response.data.response.data;
    })
    .catch((error) => {
      // toast.error(error.response.data.message);
      toast.error(HandleAxiosError(error));
      // throw HandleAxiosError(error);
    });

export const getOrders = (payload = {}) => {
  const service_id = payload.service_id || ""; // Default value or handle missing service_id

  // Check if service_id is provided
  if (!service_id) {
    return Promise.reject("Service ID is required.");
  }

  // Service ID is provided, make the API call
  return ApiClient.get(`/get_card?service_id=${service_id}`)
    .then((response) => {
      return response.data.response.data;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};

export const deleteOrder = ({ id, payload }) => {
  return ApiClient.delete(`/orders/item/${id}`, payload)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data.response.data.id);
      return response.data.response.data;
    })
    .catch((error) => {
      // toast.error(error);
      toast.error(HandleAxiosError(error));
      // throw HandleAxiosError(error);
    });
};

export const addCoupon = (payload) =>
  ApiClient.post("/add_coupon", payload)
    .then((response) => {
      toast.success(response.data.message);
      return response.data.response.data;
    })
    .catch((error) => {
      // toast.error(error.response.data.message);
      toast.error(HandleAxiosError(error));
      // throw HandleAxiosError(error);
    });
