import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "components/Utils/intlMessages";
import {
  ServicesIcon,
  CategoriesIcon,
  CartIcon,
  UserIcon,
  OtherIcon,
  HomeIcon,
} from "assets/icons"; // Import appropriate icons
import { useSelector } from "react-redux";
import ServicesSplash from "./ServicesSplash"; // Import splash screen components
import CategoriesSplash from "./CategoriesSplash";
import AccountSplash from "./AccountSplash"; // New import
import OtherSplash from "./OtherSplash"; // New import
import { useGetCategories } from "apis/categories";
const MobileNavbar = ({ locale, isLoggedIn, handleTriggerModalSignIn }) => {
  const history = useHistory();
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const { cart, isLoading: isFetching } = useSelector(
    (state) => state.Order.orders
  );
  const [selectedService, setSelectedService] = useState(
    localStorage.getItem("selectedService")
  );

  useEffect(() => {
    const handleStorageUpdate = () => {
      const newSelectedService = localStorage.getItem("selectedService");
      setSelectedService(newSelectedService);
    };

    window.addEventListener("localStorageUpdated", handleStorageUpdate);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("localStorageUpdated", handleStorageUpdate);
    };
  }, []);
  const { data: categoriesData, isLoading } = useGetCategories({
    service_id: selectedService,
    is_tree: true,
    enabled: true,
    limit: 1000,
  });
  const [showServices, setShowServices] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showOther, setShowOther] = useState(false);
  return (
    <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 z-50 md:hidden flex justify-around items-center p-2">
      {showServices && (
        <ServicesSplash onClose={() => setShowServices(false)} />
      )}
      {showCategories && !isLoading && categoriesData?.data?.length > 0 && (
        <CategoriesSplash
          onClose={() => setShowCategories(false)}
          isLoading={isLoading}
          categoriesData={categoriesData}
        />
      )}
      {showAccount && <AccountSplash onClose={() => setShowAccount(false)} />}{" "}
      {showOther && (
        <OtherSplash onClose={() => setShowOther(false)} locale={locale} />
      )}{" "}
      {!isRemoteConfigLoading &&
      remoteConfig?.enable_services_page &&
      location.pathname !== "/" &&
      localStorage.getItem("district_id") ? (
        <button
          onClick={() => setShowServices(true)}
          className={`flex flex-col items-center ${
            showServices ? "text-primary-500" : "text-gray-500"
          }`}
        >
          <ServicesIcon
            size={30}
            className={`${showServices ? "text-primary-500" : "text-gray-500"}`}
          />
          <span className="text-xs">
            {" "}
            <IntlMessages id="global.services" />
          </span>
        </button>
      ) : (
        <button
          onClick={() => history.push("/home")}
          className={`flex flex-col items-center text-gray-500`}
        >
          <HomeIcon size={30} className="text-gray-500" />
          <span className="text-xs">
            {" "}
            <IntlMessages id="global.home" />
          </span>
        </button>
      )}
      {!isServiceDetailsLoading &&
        serviceDetails?.show_categories_menu &&
        location.pathname !== "/" &&
        localStorage.getItem("district_id") && (
          <button
            onClick={() => setShowCategories(true)}
            className={`flex flex-col items-center ${
              showCategories ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <CategoriesIcon
              size={30}
              className={`${
                showCategories ? "text-primary-500" : "text-gray-500"
              }`}
            />
            <span className="text-xs">
              <IntlMessages id="global.categories" />
            </span>
          </button>
        )}
      {isLoggedIn ? (
        <>
          <Link to="/cart" className="flex flex-col items-center relative">
            <CartIcon size={30} className="text-gray-500" />
            {cart?.items_quantity > 0 && !isFetching && (
              <div className="absolute top-0 right-0 mt-1 mr-3 bg-primary-500 text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center">
                {cart?.items_quantity}
              </div>
            )}
            <span className="text-xs">
              {" "}
              <IntlMessages id="global.cart" />
            </span>
          </Link>

          <button
            onClick={() => setShowAccount(true)}
            className={`flex flex-col items-center ${
              showAccount ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <UserIcon
              size={30}
              className={`${
                showAccount ? "text-primary-500" : "text-gray-500"
              }`}
            />
            <span className="text-xs">
              {" "}
              <IntlMessages id="global.account" />
            </span>
          </button>
        </>
      ) : (
        <>
          <button
            onClick={handleTriggerModalSignIn}
            className="flex flex-col items-center"
          >
            <UserIcon size={30} className="text-gray-500" />
            <span className="text-xs">
              {" "}
              <IntlMessages id="global.login" />
            </span>
          </button>
          {/* <button
            onClick={() => setShowOther(true)}
            className={`flex flex-col items-center ${
              showOther ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <OtherIcon
              size={30}
              className={`${showOther ? "text-primary-500" : "text-gray-500"}`}
            />
            <span className="text-xs">
              {" "}
              <IntlMessages id="global.other" />
            </span>
          </button> */}
        </>
      )}
    </div>
  );
};

export default MobileNavbar;
