import React from "react";
import { Field, ErrorMessage } from "formik";
const styles = {
  label: "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold pb-1",
  field:
    "border text-sm border-gray-250 text-secondary-950 focus:outline-none focus:shadow-outline py-[0.69rem] w-full rounded appearance-none",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-secondary-950 bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-[0.69rem] w-full rounded appearance-none border-error-900",
};
function Select(props) {
  const {
    firstOption,
    placeholder,
    label,
    errors,
    touched,
    name,
    options,
    ...rest
  } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        as="select"
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled hidden selected>
            {placeholder}
          </option>
        )}
        {firstOption && <option value="">{firstOption}</option>}
        {options?.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default Select;
