/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "components/Buttons/Button";
import IntlMessages from "components/Utils/intlMessages";
import LoginDropdown from "components/Dropdowns/LoginDropdown.js";
import LangDropdown from "components/Dropdowns/LangDropdown.js";
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown.js";
import CurrencyDropdown from "components/Dropdowns/CurrencyDropdown";
import actions from "redux/languageSwitcher/actions";
import { useDispatch, useSelector } from "react-redux";
import Search from "components/Search/SearchForm";
import { FavIcon, CartIcon, NotiIcon, UserIcon } from "assets/icons";
import { MapIcon, ChevronDownIcon } from "assets/icons";
import LocationModal from "components/Maps/LocationModal";
import AutocompleteSearchInput from "components/Search/AutocompleteSearchInput";
import LocationsDropdown from "components/Dropdowns/LocationsDropdown";
const options = [
  { label: "Apple", value: "apple" },
  { label: "Banana", value: "banana" },
  { label: "Cherry", value: "cherry" },
  { label: "Grape", value: "grape" },
  { label: "Orange", value: "orange" },
  { label: "Pineapple", value: "pineapple" },
];
import { get as _get } from "lodash";
import { actions as orderActions } from "redux/order/actions";
import Services from "./Services";

export default function Navbar({ settings, isLoading, locale }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const handleToggleModal = () => setShowLocationsModal(!showLocationsModal);
  const User = useSelector((state) => state.User);
  const [location, setLocation] = useState({});

  const loggedUser = User.user;
  const isLoggedIn = _get(User, "token", false);
  const { cart, isLoading: isFetching } = useSelector(
    (state) => state.Order.orders
  );
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const handleLocationClick = () => {
    if (isLoggedIn) {
      history.push("/account/my-address");
    } else {
      setShowLocationsModal(true);
    }
  };
  const [selectedAddress, setSelectedAddress] = useState(
    JSON.parse(localStorage.getItem("selectedAddress"))
  );
  useEffect(() => {
    if (selectedAddress) {
      // console.log("selectedAddress", selectedAddress);
      localStorage.setItem("selectedAddress", JSON.stringify(selectedAddress));
      // localStorage.setItem("location", JSON.stringify(location));
    }
  }, [selectedAddress]);
  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <>
      <nav className="hidden md:block relative border-b border-gray-80 bg-white text-xs py-2 text-secondary-950">
        <div className="container flex flex-col gap-3 md:flex-row items-center justify-between">
          <Services locale={locale} />
          {selectedAddress && (
            <Button
              onClick={handleLocationClick}
              className="flex items-center gap-3 "
              variant="text"
              size="auto"
            >
              <MapIcon size={20} />
              <span className="whitespace-nowrap font-inter-semibold">
                {selectedAddress?.name
                  ? truncateText(selectedAddress?.name, 20)
                  : selectedAddress?.region
                  ? truncateText(selectedAddress?.region, 20)
                  : truncateText(selectedAddress?.streetAddress, 20)}
              </span>
              <ChevronDownIcon />
            </Button>
          )}
        </div>
      </nav>
      {showLocationsModal && (
        <LocationModal
          handleCloseModal={handleToggleModal}
          location={location}
          setLocation={setLocation}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
    </>
  );
}
