import React, { useRef, useEffect, useState } from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import BasicSkeleton from "components/Skeleton/BasicSkeleton";
import { useDispatch, useSelector } from "react-redux";

const MapContainer = ({
  google,
  location,
  onLocationChange,
  selectedAddress,
  setSelectedAddress,
  addressData,
}) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [map, setMap] = useState(null);
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);

  const DEFAULT_LOCATION = !isRemoteConfigLoading && {
    lat: remoteConfig.default_location.latitude,
    lng: remoteConfig.default_location.longitude,
  };
  // console.log(DEFAULT_LOCATION);

  useEffect(() => {
    let isMounted = true;

    const fetchCurrentLocation = () => {
      if (!location) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              const currentLocation = {
                lat: latitude,
                lng: longitude,
              };
              if (isMounted) {
                setCurrentLocation(currentLocation);
                onLocationChange(currentLocation);
                setLoading(false);
                fetchAddress(currentLocation);
              }
            },
            (error) => {
              console.error("Error retrieving current location:", error);
              if (isMounted) {
                setCurrentLocation(DEFAULT_LOCATION);
                onLocationChange(DEFAULT_LOCATION);
                setLoading(false);
              }
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          if (isMounted) {
            setCurrentLocation(DEFAULT_LOCATION);
            onLocationChange(DEFAULT_LOCATION);
            setLoading(false);
          }
        }
      } else {
        if (isMounted) {
          setCurrentLocation(location);
          onLocationChange(location);
          setLoading(false);
          fetchAddress(location);
        }
      }
    };

    fetchCurrentLocation();

    return () => {
      isMounted = false;
    };
  }, [location]);

  const setAddressObject = (results) => {
    const addressObject = {};
    for (let i = 0; i < results.length; i++) {
      const addressComponents = results[i].address_components;
      for (let j = 0; j < addressComponents.length; j++) {
        const types = addressComponents[j].types;
        if (types.includes("postal_code")) {
          addressObject.postalCode = addressComponents[j].long_name;
        } else if (types.includes("locality")) {
          addressObject.city = addressComponents[j].long_name;
        } else if (types.includes("administrative_area_level_1")) {
          addressObject.region = addressComponents[j].long_name;
        } else if (types.includes("country")) {
          addressObject.country = addressComponents[j].long_name;
        }
      }
    }
    addressObject.streetAddress = results[0].formatted_address;
    setSelectedAddress(addressObject);
  };

  const fetchAddress = (location) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK" && results[0]) {
        // console.log("results", results);
        setAddressObject(results);
      } else {
        console.error("Geocode request failed. Status:", status);
      }
    });
  };

  const toggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
  };

  const handleMapClick = (mapProps, map, clickEvent) => {
    const location = {
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng(),
    };
    onLocationChange(location);
    if (markerRef.current) {
      markerRef.current.marker.setPosition(location);
    }
    fetchAddress(location);
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleAddressBlur = () => {
    // Perform any necessary actions when the address input is blurred
    // For example, you can make a geocoding request to get the coordinates of the new address
    // and update the location accordingly
  };

  const handleMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
    setShowInfoWindow(false);
  };

  const mapCenter = loading ? { lat: 0, lng: 0 } : currentLocation;
  const editLocation = addressData && addressData.location !== location;

  return (
    <div style={{ height: "400px", width: "100%" }}>
      {!loading ? (
        <Map
          google={google}
          zoom={14}
          initialCenter={
            location &&
            Object.keys(location).length !== 0 &&
            isFinite(location.lat) &&
            isFinite(location.lng)
              ? location
              : DEFAULT_LOCATION
          }
          center={
            editLocation
              ? addressData.location
              : location &&
                Object.keys(location).length !== 0 &&
                isFinite(location.lat) &&
                isFinite(location.lng)
              ? location
              : DEFAULT_LOCATION
          }
          onClick={handleMapClick}
          onReady={(mapProps, map) => {
            mapRef.current = map;
            setMap(map);
          }}
          draggable={true}
          zoomControl={true}
          mapTypeControl={true}
          streetViewControl={true}
          fullscreenControl={true}
          options={{
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.TOP_RIGHT,
            },
          }}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {currentLocation && (
            <Marker
              ref={markerRef}
              position={currentLocation}
              onClick={handleMarkerClick}
              draggable={!editMode}
              onDragend={(t, map, coord) => {
                const { latLng } = coord;
                const lat = latLng.lat();
                const lng = latLng.lng();
                const location = {
                  lat,
                  lng,
                };
                onLocationChange(location);
                fetchAddress(location);
              }}
            />
          )}
          {selectedAddress && (
            <InfoWindow
              marker={activeMarker}
              visible={showInfoWindow}
              onClose={handleInfoWindowClose}
            >
              <div className="pr-5 flex flex-col items-center gap-1 text-secondary-950 text-xs font-inter-medium">
                <p>{selectedAddress.streetAddress}</p>
                <p>{selectedAddress.city}</p>
                <p>{selectedAddress.postalCode}</p>
                <p>{selectedAddress.country}</p>
              </div>
            </InfoWindow>
          )}
        </Map>
      ) : (
        <BasicSkeleton />
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC84xaxrAOPzK27rKOefMTwwj0oqFhGtCI",
})(MapContainer);
