import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { actions } from "redux/auth/actions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Messages from "./i18nStrings";
import IntlMessages from "components/Utils/intlMessages";
import Divider from "components/Divider/Divider";
import Modal from "components/Modals/Modal";
import qs from "query-string";
import Button from "components/Buttons/Button";
import FormikController from "components/Forms/FormikController";
import { RegisterSchema } from "components/validation/RegisterSchema";
import { useGetStaticPages } from "apis/information";
import { ArrowLeftIcon } from "assets/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
const SignUp = ({ handleCloseModal }) => {
  const location = useLocation();
  const { data, isListLoading } = useGetStaticPages({
    category: "customer",
  });
  const { isLoading, isSuccess, isFailed, error, successMessage } = useSelector(
    (state) => state.Auth.register,
    shallowEqual
  );
  useEffect(() => {
    localStorage.removeItem("@matjr__statusModal");
  }, []);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const history = useHistory();

  const initialValues = {
    name: "",
    email: "",
    referral_code: "",
    acceptTerms: false,
  };

  useEffect(() => {
    if (isFailed) {
      toast.error(error);
    }
    if (isSuccess) {
      toast.success(Messages.getFormatMessage("accountCreated"));
    }
  }, [isFailed, isSuccess, error]);

  const onSubmit = (values) => {
    const code = localStorage.getItem("@matjr__userCode");
    const mobile = localStorage.getItem("@matjr__userMobile");
    dispatch(
      actions.registerRequest({
        ...values,
        user_register: "user",
        mobile: mobile,
        code: code,
      })
    );
  };

  return (
    <Modal
      title={Messages.getFormatMessage("signUp")}
      handleCloseModal={handleCloseModal}
      actionsPannel={false}
      size={"w-full lg:w-1/3"}
      color={"primary"}
      bg={"white"}
    >
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        validationSchema={RegisterSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        render={({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          dirty,
        }) => (
          <Form>
            <FormikController
              control="input"
              errors={errors.name}
              touched={touched.name}
              type="text"
              label={Messages.getFormatMessage("fullNameLabel")}
              name="name"
            />
            <FormikController
              control="input"
              errors={errors.email}
              touched={touched.email}
              type="email"
              label={Messages.getFormatMessage("emailAddressLabel")}
              name="email"
            />
            <FormikController
              control="input"
              errors={errors.referral_code}
              touched={touched.referral_code}
              type="text"
              label={Messages.getFormatMessage("referralCodeLabel")}
              name="referral_code"
            />
            <Field
              type="checkbox"
              name="acceptTerms"
              className={
                "focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 " +
                (errors.acceptTerms && touched.acceptTerms
                  ? " text-error-500 accent-error-50 border-error-50 focus:ring-error-50"
                  : "text-primary accent-primary focus:ring-primary")
              }
            />
            <label
              htmlFor="acceptTerms"
              className="form-check-label text-sm leading-3 mt-3 text-center font-inter-regular text-secondary-950"
            >
              {" "}
              {Messages.getFormatMessage("iAgree")}{" "}
              {!isListLoading &&
                data
                  ?.filter((item) => item.type === "policy")
                  .map((item, index) => (
                    <Link
                      to={`/account/information/${item.id}`}
                      target="_blank"
                      className="text-primary-400 underline me-3"
                    >
                      {" "}
                      {item.title}
                      {/* {Messages.getFormatMessage("termsConditions")}{" "} */}
                    </Link>
                  ))}
            </label>
            <ErrorMessage
              name="acceptTerms"
              component="p"
              className="text-error-500 text-4xs leading-3 pt-2 pb-1"
            />
            <Button
              className="w-full mt-3 text-center"
              type="submit"
              size="large"
              loading={isLoading}
              disabled={!(isValid && dirty)}
            >
              {Messages.getFormatMessage("signUpButton")}
            </Button>
          </Form>
        )}
      />
      {/* <p className="flex items-center gap-2 justify-center mt-5 text-base ltr:font-inter-medium rtl:font-semibold text-gray-30">
        {" "}
        <ArrowLeftIcon />
        <Link to="/signin"> {Messages.getFormatMessage("backToLogIn")} </Link>
      </p> */}
    </Modal>
  );
};

export default SignUp;
