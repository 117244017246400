/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";

export default function FeaturedLinks({ links, isLoading }) {
  return (
    <>
      {isLoading ? null : (
        <ul className="flex gap-2 md:gap-5 justify-center lg:justify-start list-none">
          {links?.map((link) => (
            <li className="flex-shrink-0">
              <Link
                to={`/categories/${link.id}`}
                className="text-base  font-inter-medium text-gray-500 w-full flex items-center gap-2 md:gap-3 text-left"
              >
                <img
                  src={link.icon}
                  className="w-5 h-5 object-contain flex-shrink-0 "
                />
                {link.name?.substring(0, 20)}{" "}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
