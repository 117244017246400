import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

export const selectedServiceRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.SELECTED_SERVICE_REQUEST),
    switchMap((action) =>
      Api.getSelectedService(action.payload)
        .then((response) => {
          return actions.selectedServiceRequestSuccess(response);
        })
        .catch((error) => actions.selectedServiceRequestFailed(error))
    )
  );

export default [selectedServiceRequest];
