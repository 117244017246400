import config, { getCurrentLanguage } from "./config";
import client from "utils/plugins/ApiClient";
import actions from "./actions";

const langLocal = localStorage.getItem("@matjr-lang");

const initState = {
  isActivated: false,
  language: langLocal
    ? getCurrentLanguage(langLocal)
    : getCurrentLanguage(config.defaultLanguage || "english"),
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated,
      };
    case actions.CHANGE_LANGUAGE:
      !localStorage.getItem("@matjr__token") && 
        localStorage.setItem("@matjr-lang", action.language.languageId);
      // window.location.reload();
    return {
      ...state,
      language: action.language,
    };
    default:
      return state;
  }
}
