/*eslint-disable*/
import IntlMessages from "components/Utils/intlMessages";
import React from "react";
import { Link } from "react-router-dom";

export default function AuthenticationNavbar({ settings, isLoading, locale }) {
  return (
    <>
      <nav className="bg-primary-400 py-5">
        <div className="container">
          <div className="w-full px-4">
            <Link
              to="/"
              className="text-sm leading-2 ltr:font-inter-semibold rtl:font-semibold text-secondary-950"
            >
              {locale === "ar"
                ? settings?.general_setting.store_name_ar
                : settings?.general_setting.store_name_en}
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
