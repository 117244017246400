import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

export const settingsRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.SETTINGS_REQUEST),
    switchMap((action) =>
      Api.getSettings(action.payload)
        .then((response) => {
          return actions.settingsRequestSuccess(response);
        })
        .catch((error) => actions.settingsRequestFailed(error))
    )
  );

export default [settingsRequest];
