import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { actions } from "redux/auth/actions";
import { Formik, Form } from "formik";
import Messages from "./i18nStrings";
import Modal from "components/Modals/Modal";
import Button from "components/Buttons/Button";
import FormikController from "components/Forms/FormikController";
import { LoginSchema } from "components/validation/LoginSchema";
import { useHistory } from "react-router-dom";
const SignIn = ({
  handleCloseModal,
  handleTriggerModalVerifyOtp,
  selectedChannel,
  setSelectedChannel,
  setSelectedMobile,
  selectedMobile,
}) => {
  const dispatch = useDispatch();
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const history = useHistory();
  // console.log(selectedMobile);
  useEffect(() => {
    localStorage.removeItem("@matjr__statusModal");
    dispatch(actions.loginOpen(false));
  }, []);
  const ref = useRef(null);
  const initialValues = {
    mobile: "",
    channel: selectedChannel,
  };

  const { isLoading, isSuccess, isFailed, error } = useSelector(
    (state) => state.Auth.login,
    shallowEqual
  );

  useEffect(() => {
    if (isFailed) {
      toast.error(error);
    }
    if (isSuccess) {
      toast.success(Messages.getFormatMessage("otpSent"));
      handleCloseModal();
      localStorage.setItem("@matjr__statusModal", "verifyOtp");
      localStorage.setItem("@matjr__userMobile", ref.current.values.mobile);
      handleTriggerModalVerifyOtp();
    }
    const timeout = setTimeout(() => {
      dispatch(actions.flushAuthReducer());
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isFailed, isSuccess, error]);

  const onSubmit = (values) => {
    // console.log("values", values);
    setSelectedMobile(values.mobile);
    dispatch(actions.loginRequest(values));
  };

  return (
    <>
      <Modal
        title={Messages.getFormatMessage("signInTitle")}
        handleCloseModal={handleCloseModal}
        actionsPannel={false}
        size={"w-full lg:w-1/3"}
        color={"primary"}
        bg={"white"}
      >
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validateOnChange={true}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <Form autoComplete="off" id="input-login">
              <FormikController
                control="phoneNumber"
                errors={errors.mobile}
                touched={touched.mobile}
                type="text"
                label={Messages.getFormatMessage("mobileLabel")}
                name="mobile"
                placeholder={Messages.getFormatMessage("mobileLabel")}
                setFieldValue={setFieldValue}
                value={values.mobile}
                defaultCountry={
                  !isRemoteConfigLoading && remoteConfig.default_country_code
                }
              />

              <div className="flex flex-col items-center gap-3">
                {!isRemoteConfigLoading &&
                  remoteConfig.verification_methods_sms &&
                  remoteConfig.verification_methods_whatsapp && (
                    <p className="text-base leading-4 text-center ltr:font-inter-medium rtl:font-semibold text-gray-30">
                      {Messages.getFormatMessage("methodMsg")}
                    </p>
                  )}
                {!isRemoteConfigLoading &&
                  remoteConfig.verification_methods_sms && (
                    <Button
                      className="w-full text-center"
                      type="submit"
                      size="large"
                      loading={selectedChannel === "sms" && isLoading}
                      disabled={!(isValid && dirty)}
                      onClick={() => setSelectedChannel("sms")}
                    >
                      {Messages.getFormatMessage("viaSms")}
                    </Button>
                  )}
                {!isRemoteConfigLoading &&
                  remoteConfig.verification_methods_sms &&
                  remoteConfig.verification_methods_whatsapp && (
                    <div className="inline-flex items-center justify-center w-full">
                      <hr className="w-64 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
                      <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                        {Messages.getFormatMessage("or")}
                      </div>
                    </div>
                  )}
                {!isRemoteConfigLoading &&
                  remoteConfig.verification_methods_whatsapp && (
                    <Button
                      className="w-full text-center bg-success-700 border-success-700 hover:bg-success-600 hover:border-success-700"
                      type="submit"
                      size="large"
                      loading={selectedChannel === "whatsapp" && isLoading}
                      disabled={!(isValid && dirty)}
                      onClick={() => setSelectedChannel("whatsapp")}
                    >
                      {Messages.getFormatMessage("viaWhatsapp")}
                    </Button>
                  )}
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
};

export default SignIn;
