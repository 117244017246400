import React, { useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import IntlMessages from "components/Utils/intlMessages";

const styles = {
  label: "text-gray-700 text-3xs ltr:font-inter-medium rtl:font-semibold",
  field:
    "border border-gray-80 text-secondary-950 focus:outline-none focus:shadow-outline py-1 w-full rounded appearance-none",
  option: "flex gap-2 items-center",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-secondary-950 bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-2 w-full rounded appearance-none border-error-900",
};

function CheckBox(props) {
  const { handleClear, label, errors, touched, name, options, ...rest } = props;

  const formik = useFormikContext(); // Access the formik context

  const handleClearField = () => {
    formik.setFieldValue(name, []); // Clear the field value by setting it to an empty array
  };

  useEffect(() => {
    if (formik.values[name] === undefined) {
      formik.setFieldValue(name, []); // Initialize the field value as an empty array if it's undefined
    }
  }, [formik.values, name]);

  return (
    <div className="w-full flex flex-col py-5 gap-3">
      <div className="flex items-center justify-between">
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        <span
          onClick={() => handleClearField()} // Updated the function call
          className="text-primary-400 cursor-pointer"
        >
          <IntlMessages id="global.clear" />
        </span>
      </div>
      <Field
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
      >
        {({ field }) => {
          const selectedValues = Array.isArray(field.value) ? field.value : []; // Ensure field.value is always an array
          return options?.map((option) => {
            const isChecked = selectedValues.includes(option.id);
            return (
              <div className={styles.option} key={option.id}>
                <input
                  type="checkbox"
                  id={option.id}
                  name={name}
                  value={option.id}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      const updatedValues = selectedValues.filter(
                        (value) => value !== option.id
                      );
                      formik.setFieldValue(name, updatedValues);
                    } else {
                      const updatedValues = [...selectedValues, option.id];
                      formik.setFieldValue(name, updatedValues);
                    }
                  }}
                  className="text-primary-400 accent-primary-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 focus:ring-opacity-50"
                />
                <label className={styles.label + " " + "!font-inter-regular"}>
                  {option.name}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default CheckBox;
