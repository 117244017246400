import axios from "axios";
import history from "utils/helpers/history";
import { store, dispatch } from "../../redux";
import { actionTypes } from "redux/auth/actions";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_BE_API,
  validateStatus: (status) => {
    if (
      (status === 401 && !localStorage.getItem("@matjr__loginStatus")) ||
      status === 403
    ) {
      dispatch({
        type: actionTypes.LOGOUT,
      });
      return false;
    }
    if (status === 404) {
      history.push("/404");
    }
    if (status >= 200 && status <= 300) return true;
    return false;
  },
});

const token = localStorage.getItem("@matjr__token") || "null";
const district_id = localStorage.getItem("district_id") || "null";
const address_id = localStorage.getItem("address_id") || null;
const location = localStorage.getItem("location");
client.defaults.headers.common.Authorization = `Bearer ${token}`;
client.defaults.headers.common["district-id"] = district_id;
if (address_id) {
  client.defaults.headers.common["address-id"] = address_id;
}
if (location) {
  try {
    const loc = JSON.parse(location);
    if (loc && typeof loc === "object" && Object.keys(loc).length > 0) {
      if (loc.lng) client.defaults.headers.common["longitude"] = loc.lng;
      if (loc.lat) client.defaults.headers.common["latitude"] = loc.lat;
    }
  } catch (e) {
    console.error("Error parsing location from localStorage", e);
  }
}
client.defaults.headers.Language = "en";
client.defaults.headers["User-Agent-Type"] = "web";
client.defaults.headers["currency"] = "USD";

// client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default client;
