import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";

export const getServices = (payload) => {
  return ApiClient.get(`/services`)
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};
