import React, { useEffect, useState } from "react";
import { actions } from "redux/auth/actions";
import Modal from "components/Modals/Modal";
import Messages from "./i18nStrings";
import Button from "components/Buttons/Button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ApiClient from "utils/plugins/ApiClient";
import { MailIcon } from "assets/icons";
import HandleAxiosError from "utils/helpers/HandleAxiosError";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const VerifyOtp = ({ handleCloseModal, channel, handleTriggerModalSignUp }) => {
  const { isLoading, isSuccess, isFailed, error } = useSelector(
    (state) => state.Auth.verifyOtp,
    shallowEqual
  );
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);

  const history = useHistory();
  const dispatch = useDispatch();
  const [err, setError] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [inputValues, setInputValues] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  useEffect(() => {
    localStorage.removeItem("@matjr__statusModal");
  }, []);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleChange = (currentId, nextId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [currentId]: value,
    }));

    const nextInput = document.getElementById(nextId);

    if (nextInput) {
      nextInput.focus();
    }
  };

  const areAllInputValuesSet = Object.values(inputValues).every(
    (val) => val !== ""
  );

  const handleSendOTP = () => {
    setResendDisabled(true);
    setCountdown(60); // Set countdown to 60 seconds
    const mobile = localStorage.getItem("@matjr__userMobile");
    dispatch(actions.loginRequest({ mobile: mobile, channel: channel }));
  };

  const handleVerifyOTP = () => {
    const mobile = localStorage.getItem("@matjr__userMobile");
    const code = `${inputValues.one}${inputValues.two}${inputValues.three}${inputValues.four}`;
    if (mobile && code && channel) {
      localStorage.setItem("@matjr__userCode", code);
      dispatch(actions.verifyOtpRequest({ mobile, channel, code }));
    }
  };

  useEffect(() => {
    if (isFailed) {
      toast.error(error);
    }
    if (isSuccess) {
      const userExists = localStorage.getItem("@matjr__userExists");
      if (userExists == "true") {
        toast.success(Messages.getFormatMessage("otpVerified"));
      } else {
        toast.success(Messages.getFormatMessage("otpVerifiedRegister"));
        localStorage.setItem("@matjr__statusModal", "register");
        handleCloseModal();
        handleTriggerModalSignUp();
      }
    }
  }, [isFailed, isSuccess, error]);

  return (
    <Modal
      title={Messages.getFormatMessage("verifyOtp")}
      handleCloseModal={handleCloseModal}
      actionsPannel={false}
      size={"w-full lg:w-1/3"}
      color={"primary"}
      bg={"white"}
    >
      <div className="flex flex-col shadow-sm rounded-md text-defaulttextcolor dark:text-defaulttextcolor/70 bg-white text-[0.813rem] mb-6 relative dark:bg-bodybg">
        <div className="text-defaulttextcolor dark:text-defaulttextcolor/70 sm:!p-[3rem]">
          <p className="mb-4 text-[#8c9097] dark:text-white/50 opacity-[0.7] font-normal text-center">
            {Messages.getFormatMessage("verifyOtpDescription")}
          </p>
          <div className="grid grid-cols-12 gap-y-4">
            <div className="xl:col-span-12 col-span-12 mb-2">
              <div
                className="grid grid-cols-12 gap-4"
                style={{ direction: "ltr" }}
              >
                {["one", "two", "three", "four"].map((id, index) => (
                  <div className="col-span-3 px-1" key={id}>
                    <input
                      type="text"
                      maxLength={1}
                      inputMode="numeric"
                      pattern="\d*"
                      className="!px-0 form-control w-full !rounded-md form-control-lg text-center !text-[1rem]"
                      id={id}
                      value={inputValues[id]}
                      onChange={(e) =>
                        handleChange(
                          id,
                          ["two", "three", "four", null][index],
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="mt-5 mb-0 !ps-0">
                <label>
                  {Messages.getFormatMessage("didNotReceive")}
                  <button
                    className={`text-primary ms-2 inline-block ${
                      resendDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleSendOTP}
                    disabled={resendDisabled}
                  >
                    {Messages.getFormatMessage("resend")}
                  </button>
                </label>
              </div>

              {countdown > 0 && (
                <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4 text-danger">
                  {Messages.getFormatMessage("wait")} {countdown}{" "}
                  {Messages.getFormatMessage("resendMsg")}
                </p>
              )}
            </div>
            <div className="xl:col-span-12 col-span-12 grid mt-2">
              <Button
                size="large"
                variant="solid"
                onClick={handleVerifyOTP}
                loading={isLoading}
                disabled={isLoading || !areAllInputValuesSet}
              >
                {Messages.getFormatMessage("verify")}
              </Button>
            </div>
          </div>
          <div className="text-center">
            <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4 text-danger">
              <sup>
                <i className="ri-asterisk"></i>
              </sup>
              {Messages.getFormatMessage("verifyWarning")}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyOtp;
