import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    sortBy: {
      id: "filter.sortBy",
      defaultMessage: "Sort By",
    },
    recommended: {
      id: "filter.recommended",
      defaultMessage: "Recommended",
    },
    bestSelling: {
      id: "filter.bestSelling",
      defaultMessage: "Best Selling",
    },
    ascending: {
      id: "filter.ascending",
      defaultMessage: "Ascending A-Z",
    },
    descending: {
      id: "filter.descending",
      defaultMessage: "Descending Z-A",
    },
    highestPrice: {
      id: "filter.highestPrice",
      defaultMessage: "Highest Price",
    },
    lowestPrice: {
      id: "filter.lowestPrice",
      defaultMessage: "Lowest Price",
    },
    newest: {
      id: "filter.newest",
      defaultMessage: "Newest",
    },
    oldest: {
      id: "filter.oldest",
      defaultMessage: "Oldest",
    },

    filterBy: {
      id: "filter.filterBy",
      defaultMessage: "Filter By",
    },
    resultsFor: {
      id: "filter.resultsFor",
      defaultMessage: "Results For",
    },
    view: {
      id: "filter.view",
      defaultMessage: "View",
    },
    display: {
      id: "filter.display",
      defaultMessage: "Display",
    },
    perPage: {
      id: "filter.perPage",
      defaultMessage: "Per Page",
    },
    categories: {
      id: "filter.categories",
      defaultMessage: "Categories",
    },
    allCategories: {
      id: "filter.allCategories",
      defaultMessage: "All Categories",
    },
    exploreServices: {
      id: "filter.exploreServices",
      defaultMessage: "Explore Services",
    },
    brands: {
      id: "filter.brands",
      defaultMessage: "Brands",
    },
    rating: {
      id: "filter.rating",
      defaultMessage: "Rating",
    },
    productStock: {
      id: "filter.productStock",
      defaultMessage: "Product Stock",
    },
    inStock: {
      id: "filter.inStock",
      defaultMessage: "In Stock",
    },
    outOfStock: {
      id: "filter.outOfStock",
      defaultMessage: "Out Of Stock",
    },
    preOrder: {
      id: "filter.preOrder",
      defaultMessage: "PreOrder",
    },
    noSubcategories: {
      id: "filter.noSubcategories",
      defaultMessage:
        "No subcategories available at the moment for this category",
    },
  })
);
